import React, {useEffect, useState} from "react";
import axios from "axios";
import {TextField, InputLabel, Switch, FormControlLabel} from "@material-ui/core";
import {Button} from "react-bootstrap";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import Table from "./components/Table";
import {objectToQueryString} from "../../../_metronic/_helpers";

const date = new Date();

const initialStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().split('T')[0];
const initialEndDate = date.toISOString().split('T')[0];


export default function BigWinReport() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("ticketSettlementDate");
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [isSettlement, setIsSettlement] = useState(false);
    const [hideTestAgents, setHideTestAgents] = useState(true);
    const [state, setState] = useState({
        isLoading: false,
        error: null,
        data: null,
    });

    async function getData(sortField, orderBy, isSettlement = false) {
        if (startDate && endDate) {
            setState(prevState => ({
                ...prevState, isLoading: true
            }));
            try {
                const query = objectToQueryString({skip, take, sortField, orderBy, startDate, endDate, isSettlement, hideTestAgents})
                const {data} = await axios({
                    method: "GET",
                    url: `reports/bigwin?${query}`,
                });
                setState({
                    isLoading: false,
                    error: null,
                    data
                })
            } catch (err) {
                setState({
                    isLoading: false,
                    error: err?.response?.data,
                    data: null
                })
            }
        }
    }

    async function getExcel() {
        try {
            const {data} = await axios({
                url: 'reports/bigwin/excel',
                params: {
                    orderBy, sortField, startDate, endDate, isSettlement, hideTestAgents
                },
                method: 'GET',
                responseType: 'blob'
            })
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = 'BigWinReport.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {

        }
    }

    function handleChangeStartDate(e) {
        setStartDate(e.target.value)
    }

    function handleChangeEndDate(e) {
        setEndDate(e.target.value)
    }

    async function handleChangeSettlement() {
        startDate && endDate && setIsSettlement(val => !val)
    }

    useEffect(() => {
        getData(sortField, orderBy, isSettlement)
    }, [sortField, orderBy, isSettlement, hideTestAgents, startDate, endDate, skip, take])

    return (
        <div className="row bg-white rounded">
            <div className="col-12 py-4 overflow-auto">
                <div className="row pb-5">
                    <div className="col-3 col-md-3 col-lg-3">
                        <InputLabel>
                            Start date
                        </InputLabel>
                        <TextField
                            value={startDate}
                            onChange={handleChangeStartDate}
                            type="date"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <InputLabel>
                            End date
                        </InputLabel>
                        <TextField
                            value={endDate}
                            onChange={handleChangeEndDate}
                            type="date"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 d-flex align-items-end">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isSettlement}
                                    onChange={handleChangeSettlement}
                                    color="secondary"
                                />
                            }
                            disabled={!state?.data}
                            label="Settlement"
                            className="mb-0"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 d-flex align-items-end">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hideTestAgents}
                                    onChange={() => setHideTestAgents(prevState => !prevState)}
                                    color="secondary"
                                />
                            }
                            disabled={state.isLoading}
                            label="Test agentləri gizlə"
                            className="mb-0"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 d-flex align-items-end justify-content-between">
                        <RenderIf condition={state?.data?.data?.length}>
                            <Button onClick={getExcel} variant="outline-success">Download excel</Button>
                        </RenderIf>
                    </div>
                </div>
                <RenderIf condition={state?.data}>
                    <Table
                        sortField={sortField}
                        orderBy={orderBy}
                        setSortField={setSortField}
                        setOrderBy={setOrderBy}
                        data={state?.data}
                        getData={getData}
                        take={take}
                        setTake={setTake}
                        setSkip={setSkip}
                        skip={skip}
                    />
                </RenderIf>
                <RenderIf condition={state?.isLoading}>
                    <div className="d-flex justify-content-center">
                        <Loading/>
                    </div>
                </RenderIf>
            </div>
        </div>
    )
}