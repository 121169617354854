import React from "react";
import PropTypes from "prop-types";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import {makeStyles} from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination/index";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {format} from "date-fns";
import {ImKey} from "react-icons/im"
import {azAZ} from '@material-ui/core/locale';
import {shallowEqual, useSelector} from "react-redux";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    CircularProgress,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core";
import axios from "axios";

const theme = createMuiTheme(azAZ);


const headRows = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Ad",
    },
    {
        id: "surname",
        numeric: false,
        disablePadding: true,
        label: "Soyad",
    },
    {
        id: "roleName",
        numeric: false,
        disablePadding: true,
        label: "Role name",
    },
    {
        id: "lastLoginDate",
        numeric: false,
        disablePadding: true,
        label: "Son giriş tarixi",
    },
    {
        id: "record_date_time",
        numeric: false,
        disablePadding: true,
        label: "Yaradılma tarixi",
    },
    {
        id: "createdByName",
        numeric: false,
        disablePadding: true,
        label: "Yaratmışdır",
    },
    {
        id: "updatedAt",
        numeric: false,
        disablePadding: true,
        label: "Yenilənmə tarixi",
    },
    {
        id: "updatedByName",
        numeric: false,
        disablePadding: true,
        label: "Yeniləmişdir",
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "E-poçt",
    },
    {
        id: "operations",
        numeric: false,
        disablePadding: true,
        label: "Əməliyyatlar",
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "10px",
        borderRadius: "18px",
        background: "#F5F5F5",
        padding: "0 8px 8px 8px"
    },
    tableRow: {
        borderRadius: "18px",
        border: "unset",
        padding: "16px",
        "&:hover": {
            backgroundColor: "unset !important"
        }
    },
    table: {
        minWidth: 750,
        borderCollapse: "separate",
        borderSpacing: "0 4px"
    },
    tableHeadCell: {
        border: "unset",
        padding: "3px",
        '&:first-child': {
            paddingLeft: "10px"
        },
        '&:last-child': {
            paddingRight: "10px"
        }

    },
    tableCell: {
        border: "unset",
        background: "#FFFFFF",
        marginBottom: "16px",
        padding: "3px",
        '&:first-child': {
            borderRadius: "18px 0 0 18px",
            paddingLeft: "10px"
        },
        '&:last-child': {
            borderRadius: "0 18px 18px 0",
            paddingRight: "10px"
        }
    },
    tableWrapper: {
        overflowX: "auto",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
}));

function EnhancedTableHead(props) {
    const {
        orderBy,
        sortField,
        onRequestSort,
    } = props;

    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {headRows.map((row, index) => {
                    if (row.id === "operations") {
                        return (
                            <TableCell
                                key={index}
                                align={row.numeric ? "right" : "left"}
                                className={classes.tableHeadCell}
                            >
                                <div>
                                    {row.label}
                                </div>
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell sortDirection={sortField === row.id ? "asc" : false} key={index}
                                       align={row.numeric ? "right" : "left"} className={classes.tableHeadCell}>
                                <TableSortLabel active={sortField === row.id} direction={orderBy ? "asc" : "desc"}
                                                onClick={() => {
                                                    onRequestSort(row.id)
                                                }}>
                                    {row.label}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
                                          groups,
                                          setUserId,
                                          onGroups,
                                          isLoading,
                                          isError,
                                          setChooseGroupId,
                                          setShowRemove,
                                          setShowEdit,
                                          setShowEditPass,
                                          deleteGroupsIds,
                                          setDeleteGroupsIds,
                                          sortField,
                                          setSortField,
                                          orderBy,
                                          setOrderBy,
                                          totalCount,
                                          setTake,
                                          setSkip,
                                          skip,
                                          take,
                                          filterInputs,
                                      }) {
    const classes = useStyles();

    const {
        userId,
        Admin_Update,
        Admin_Delete,
        Admin_ChangeActiveStatus,
        Admin_ChangePassword
    } = useSelector(({auth}) => ({
        userId: auth.user.id,
        Admin_Create: auth.permissions?.Admin_Create,
        Admin_Update: auth.permissions?.Admin_Update,
        Admin_Delete: auth.permissions?.Admin_Delete,
        Admin_ChangeActiveStatus: auth.permissions?.Admin_ChangeActiveStatus,
        Admin_ChangePassword: auth.permissions?.Admin_ChangePassword
    }), shallowEqual);

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = groups.map((n) => n.id);
            setDeleteGroupsIds(newSelecteds);
            return;
        }
        setDeleteGroupsIds([]);
    }

    const isSelected = (name) => deleteGroupsIds.indexOf(name) !== -1;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (field) => {

        const isOrder = (field === sortField && !orderBy)

        setSortField(field);
        setOrderBy(isOrder)

    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
        setSkip(take * newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setTake(+event.target.value);
        setPage(0);
        setSkip(0);
    }

    const changeActiveStatus = (id) => {
        axios.put(`${process.env.REACT_APP_API_URL}users/changeActiveStatus?id=${id}`)
            .then(() => {
                onGroups(filterInputs, skip, take, orderBy);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className={classes.root}>
            {isLoading && (
                <div className={classes.progresRoot}>
                    <CircularProgress className={classes.progress}/>
                </div>
            )}
            <div className={classes.tableWrapper}>
                {groups.length
                    ?
                    (<Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={deleteGroupsIds.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={groups.length}
                            onRequestSort={handleRequestSort}
                            sortField={sortField}
                            orderBy={orderBy}
                        />
                        <TableBody>
                            {groups.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        className={classes.tableRow}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.surname}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.roleName}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.lastLoginDate ? format(new Date(row.lastLoginDate), 'dd.MM.yyy HH:mm') : "-"}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.createdAt ? format(new Date(row.createdAt), 'dd.MM.yyy HH:mm') : "-"}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.createdByName ? row.createdByName : "-"}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.updatedAt ? format(new Date(row.updatedAt), 'dd.MM.yyy HH:mm') : "-"}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.updatedByName ? row.updatedByName : "-"}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {row.email}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="default"
                                            className={classes.tableCell}
                                        >
                                            {userId === row.id ? "" :
                                                Admin_ChangeActiveStatus ?
                                                    <Tooltip title={row.isActive ? "Deaktiv et" : "Aktivləşdir"}
                                                             placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-secondary mr-2"
                                                            onClick={() => {
                                                                changeActiveStatus(row.id)
                                                            }}
                                                        >
                                                            {row.isActive ?
                                                                <span
                                                                    className="active-user"><PowerSettingsNewIcon/></span> :
                                                                <span
                                                                    className="deactive-user"><PowerSettingsNewIcon/></span>}
                                                        </button>
                                                    </Tooltip>
                                                    : ""
                                            }
                                            {Admin_ChangePassword && (
                                                <Tooltip title="Şifrəni dəyiş" placement="top">
                                                    <button
                                                        aria-label="edit-password"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning mr-2"
                                                        onClick={() => {
                                                            setChooseGroupId(row.id);
                                                            setShowEditPass(true);
                                                        }}
                                                    >
                                                        <ImKey/>
                                                    </button>
                                                </Tooltip>
                                            )}

                                            {Admin_Update && (
                                                <Tooltip title="Düzəliş et" placement="top">
                                                    <button
                                                        aria-label="edit"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                                                        onClick={() => {
                                                            setChooseGroupId(row.id);
                                                            setShowEdit(true);
                                                            setUserId(row.id)
                                                        }}
                                                    >
                                                        <EditIcon/>
                                                    </button>
                                                </Tooltip>
                                            )}

                                            {userId === row.id ? "" :
                                                Admin_Delete ? <Tooltip title="Sil" placement="top">
                                                    <button
                                                        aria-label="Delete"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                                                        onClick={() => {
                                                            setChooseGroupId(row.id);
                                                            setShowRemove(true);
                                                        }}
                                                    >
                                                        <DeleteIcon/>
                                                    </button>
                                                </Tooltip> : ""}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>)
                    : !groups.length && !isLoading && !isError
                        ?
                        (<AlertComponent variant="info" message="Məlumat tapılmadı."/>)
                        : (!isLoading && isError && (<AlertComponent
                            variant="danger"
                            message="Şəbəkəylə bağlantı kəsildi."
                        />))
                }
            </div>
            {totalCount > 10 &&
                (<ThemeProvider theme={theme}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next Page",
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </ThemeProvider>)
            }
        </div>
    );
}
