import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { FormControlLabel, Switch } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import RenderIf from "app/components/RenderIf";

const initialValues = {
  retryCount: "",
};

export default function CountPermissions(props) {
  const token = useSelector((state) => state.auth.token);

  const {
    show,
    onHide,
    onGroups,
    skip,
    take,
    orderBy,
    sortField,
    filterInputs,
    chooseGroupId,
  } = props;

  const [isError, setIsError] = useState("");

  const CreateSchema = Yup.object().shape({
    retryCount: Yup.number()
      .integer("Tam ədəd daxil edin")
      .typeError("Rəqəm daxil edin.")
      .required("Sayı daxil edin."),
  });

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const onPostGroup = (values, resetForm, setSubmitting) => {
    axios({
      method: "post",
      url: `/customers/authorizeOtpRetry`,
      data: {customerId: chooseGroupId, retryCount: values.retryCount},
      headers: {
        Authorization: `bearer ${token}`,
      },
    }).then(() => {
      onGroups({ skip, take, orderBy, sortField });
      onHide(false);
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostGroup(values, resetForm, setSubmitting);
    },
  });

  const resetForm = () => {
    onHide();
    formik.resetForm();
  };

  useEffect(() => {
    setIsError("");
  }, [show]);

  return (
    <Modal
      size="md"
      aria-labelledby="create"
      centered
      className="modal pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">OTP sayını qeyd edin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {isError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block MuiFormLabel-root Mui-error">
                        {isError}
                      </div>
                    </div>
                  )}
                  <TextField
                    id="retryCount"
                    name="retryCount"
                    label="OTP sayı"
                    placeholder="OTP sayı"
                    className="w-100"
                    error={getInputClasses("retryCount")}
                    {...formik.getFieldProps("retryCount")}
                  />
                </div>
                {formik.touched.retryCount && formik.errors.retryCount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.retryCount}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Bağla
          </Button>
          <Button type="submit">Yarat</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
