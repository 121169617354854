import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import DataTables from "./components/BetHistoryTable";

import axios from "axios";
import {updateObject} from "../../../helpers/updateObject";
import {objectToQueryString} from "../../../_metronic/_helpers";
import {Input, Select} from "../../components";
import dayjs from "dayjs";
import {DateTimePicker} from "../../components/DatePicker";
import RenderIf from "../../components/RenderIf";
import CustomerBets from "./modals/CustomerBets";
import { Button } from "react-bootstrap";

const statusOptions = [
    {
        label: "Hamısı",
        value: ""
    },
    {
        label: "Pending",
        value: 1
    },
    {
        label: "Read",
        value: 2
    },
]

function BetHistory() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("createdDate");
    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalSum, setTotalSum] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [customerBets, setCustomerBets] = useState([]);
    const [showCustomerBets, setShowCustomerBets] = useState(false);
    const [filterInputs, setFilterInputs] = useState({
        agentNumber: "",
        voucherNumber: "",
        createdDateTime: "",
        startDate: "",
        endDate: ""
    });

    const params = useLocation();
    
    const history = useHistory();
    const searchParams = new URLSearchParams(params.search);

    const onGroups = (filter = {}) => {
        setIsLoading(true);

        const query = objectToQueryString({...filter, phoneNumber: searchParams.get("phoneNumber"), voucherNumber: searchParams.get("voucherNumber")});

        axios
            .get(`players/playerBets?${query}`)
            .then(({data: {data, totalCount, totalSum}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setTotalSum(totalSum)
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };




    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
    };

    const handleChangeParamsInput = (e) => {
    
        const voucherNumber = e.target.name === "voucherNumber" ? e.target.value : searchParams.get("voucherNumber");
        const phoneNumber = e.target.name === "phoneNumber" ? e.target.value : searchParams.get("phoneNumber");
        
        const search = objectToQueryString({voucherNumber, phoneNumber})

        history.push({pathname: params.pathname, search})
    }

    const downloadExcel = async () => {
        const query = objectToQueryString({...filterInputs, phoneNumber: searchParams.get("phoneNumber"), voucherNumber: searchParams.get("voucherNumber")});

        try {
            const {data} = await axios({
                url: `players/playerBets?${query}`,
                method: "GET",
                responseType: "blob",
                headers: {
                    "X-Content-Type": "application/xlsx"
                    }
            });
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "BetHistory.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
        }
    }

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [filterInputs, skip, take, orderBy, sortField, params]);


    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center pb-4">
                <h2 className="mb-0 font-weight-bolder">Customer bets</h2>
                <div className="d-flex pl-15">
                    <span className="mr-4 text-nowrap">Count: {totalCount}</span>
                    {/*<span className="mr-4 text-nowrap">Total amount: {totalSum} AZN</span>*/}
                </div>
                <Button onClick={downloadExcel} variant="outline-success" className="ml-auto">Download excel</Button>
            </div>
            <div className="d-flex w-100">
                <DateTimePicker
                    showTime
                    placeholder="Başlanğıc tarixi"
                    className="mr-2"
                    value={filterInputs.startDate ? dayjs(filterInputs.startDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, startDate: value.replace(" ", "T")
                    }))}
                />
                <DateTimePicker
                    showTime
                    placeholder="Bitmə tarixi"
                    className="mr-2"
                    value={filterInputs.endDate ? dayjs(filterInputs.endDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, endDate: value.replace(" ", "T")
                    }))}
                />
                <Input
                    name="agentNumber"
                    value={filterInputs.agentNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Agent number"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="voucherNumber"
                    value={searchParams.get("voucherNumber") || ""}
                    onChange={handleChangeParamsInput}
                    placeholder="Voucher number"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="phoneNumber"
                    value={searchParams.get("phoneNumber") || ""}
                    onChange={handleChangeParamsInput}
                    placeholder="Customer number"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Select
                    placeholder="Status"
                    options={statusOptions} onChange={(e) => setFilterInputs(prevState => ({
                    ...prevState, statusId: e.value
                }))}
                    className="mr-2"
                />
            </div>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                onGroups={onGroups}
                skip={skip}
                setSkip={setSkip}
                setTake={setTake}
                setOrderBy={setOrderBy}
                setSortField={setSortField}
                take={take}
                orderBy={orderBy}
                sortField={sortField}
                totalCount={totalCount}
                filterInputs={filterInputs}
                setCustomerBets={setCustomerBets}
                setShowCustomerBets={setShowCustomerBets}
            />
            <RenderIf condition={showCustomerBets}>
                <CustomerBets show={showCustomerBets} onHide={setShowCustomerBets} customerBets={customerBets}/>
            </RenderIf>
        </div>
    );
}

export default BetHistory;
