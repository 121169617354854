import axios from "axios";

const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}agents/changePassword`;
const UPDATE_TEST_AGENT_URL = `${process.env.REACT_APP_API_URL}agents/updateTestAgent`;
const GET_AGENTS_URL = `${process.env.REACT_APP_API_URL}agents/filter`;
const CANCEL_VOUCHER_URL = `${process.env.REACT_APP_API_URL}vouchers/cancelForAdmin`;
const UPDATE_VOUCHER_URL = `${process.env.REACT_APP_API_URL}vouchers/update`;

const changePassword = (body) => (
    axios.put(CHANGE_PASSWORD_URL, body)
)

const updateTestAgent = (query) => (
    axios.put(`${UPDATE_TEST_AGENT_URL}?${query}`,)
)

const getAgents = () => (
    axios.get(GET_AGENTS_URL)
)

const cancelVoucher = (id) => (
    axios.put(CANCEL_VOUCHER_URL, {id})
)

const updateVoucher = (body) => (
    axios.put(UPDATE_VOUCHER_URL, body)
)

export {changePassword, updateTestAgent, getAgents, cancelVoucher, updateVoucher}