import React from "react";
import PropTypes from "prop-types";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import {makeStyles} from "@material-ui/core";
import {azAZ} from "@material-ui/core/locale";
import {Badge} from "react-bootstrap";
import {format} from "date-fns";
import {useSelector} from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    CircularProgress,
    TableSortLabel,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import axios from "axios";
import RenderIf from "app/components/RenderIf";
import EditIcon from "@material-ui/icons/Edit";
import {DateRangeIcon} from "@material-ui/pickers/_shared/icons/DateRangeIcon";
import EyeIcon from "@material-ui/icons/RemoveRedEye";

const theme = createMuiTheme(azAZ);

const headRows = [
    {
        id: "phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Telefon nömrəsi",
    },
    {
        id: "lastLoginDate",
        numeric: false,
        disablePadding: true,
        label: "Son giriş tarixi",
    },
    {
        id: "registerDate",
        numeric: false,
        disablePadding: true,
        label: "Qeydiyyat tarixi",
    },
    {
        id: "isActive",
        numeric: false,
        disablePadding: true,
        label: "Aktivlik statusu",
    },
    {
        id: "confirmed",
        numeric: false,
        disablePadding: true,
        label: "Təsdiqlənmiş/Təsdiqlənməmiş",
    },
    {
        id: "birthDate",
        numeric: false,
        disablePadding: true,
        label: "Doğum tarixi",
    },
    {
        id: "playerBalance",
        numeric: false,
        disablePadding: true,
        label: "Balans",
    },
    {
        id: "operations",
        numeric: false,
        disablePadding: true,
        label: "Əməliyyatlar",
    },
];

function EnhancedTableHead({onRequestSort, sortField, orderBy}) {
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {headRows.map((row, index) => {
                    if (row.id === "operations") {
                        return (
                            <TableCell key={index} align={row.numeric ? "right" : "left"} className={classes.tableHeadCell}>
                                <div>{row.label}</div>
                            </TableCell>
                        );
                    } else {
                        return (
                            <TableCell
                                sortDirection={sortField === row.id ? "asc" : false}
                                key={index}
                                align={row.numeric ? "right" : "left"}
                                className={classes.tableHeadCell}
                            >
                                <TableSortLabel
                                    active={sortField === row.id}
                                    direction={orderBy ? "desc" : "asc"}
                                    onClick={() => {
                                        onRequestSort(row.id);
                                    }}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        marginTop: "10px",
        borderRadius: "18px",
        background: "#F5F5F5",
        padding: "0 8px 8px 8px"
    },
    tableRow: {
        borderRadius: "18px",
        border: "unset",
        padding: "16px",
        "&:hover": {
            backgroundColor: "unset !important"
        }
    },
    table: {
        minWidth: 750,
        borderCollapse: "separate",
        borderSpacing: "0 4px"
    },
    tableHeadCell: {
        border: "unset",
        padding: "3px",
        '&:first-child': {
            paddingLeft: "10px"
        },
        '&:last-child': {
            paddingRight: "10px"
        },

    },
    tableCell: {
        border: "unset",
        background: "#FFFFFF",
        marginBottom: "16px",
        '&:first-child': {
            borderRadius: "18px 0 0 18px",
            paddingLeft: "10px"
        },
        '&:last-child': {
            borderRadius: "0 18px 18px 0",
            paddingRight: "10px"
        },
        padding: "3px",
    },
    tableWrapper: {
        overflowX: "auto",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
}));

export default function EnhancedTable({
                                          groups,
                                          onGroups,
                                          isLoading,
                                          isError,
                                          deleteGroupsIds,
                                          setDeleteGroupsIds,
                                          sortField,
                                          setSortField,
                                          orderBy,
                                          setOrderBy,
                                          setSkip,
                                          setTake,
                                          skip,
                                          take,
                                          totalCount,
                                          setShowCreate,
                                          setChooseGroupId,
                                          setShowChangeBirthDate,
                                          setBirthDate
                                      }) {
    const classes = useStyles();

    const {Customer_ChangeActiveStatus, Change_Customer_Info} = useSelector(
        (state) => state.auth.user.permissions
    );


    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = groups.map((n) => n.id);
            setDeleteGroupsIds(newSelecteds);
            return;
        }
        setDeleteGroupsIds([]);
    }

    const isSelected = (name) => deleteGroupsIds.indexOf(name) !== -1;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleRequestSort = (field) => {
        const isOrder = field === sortField && !orderBy;

        setSortField(field);
        setOrderBy(isOrder);
        setTake(10);
        setRowsPerPage(10);
        setSkip(0);
        setPage(0);
    };

    function handleChangePage(event, newPage) {
        setPage(newPage);
        setSkip(take * newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setTake(+event.target.value);
        setPage(0);
        setSkip(0);
    }

    const changeActiveStatus = (id) => {
        axios
            .put(
                `customers/changeActiveStatus`,
                {id}
            )
            .then(() => {
                onGroups({skip, take, orderBy});
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className={classes.root}>
            {isLoading && (
                <div className={classes.progresRoot}>
                    <CircularProgress className={classes.progress}/>
                </div>
            )}
            <div className={classes.tableWrapper}>
                {groups.length ? (
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={deleteGroupsIds.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={groups.length}
                            onRequestSort={handleRequestSort}
                            sortField={sortField}
                            orderBy={orderBy}
                        />
                        <TableBody>
                            {groups.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        selected={isItemSelected}
                                    >
                                        <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                            {row.phoneNumber}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                            {row.lastLoginDate
                                                ? format(
                                                    new Date(row.lastLoginDate),
                                                    "dd.MM.yyyy HH:mm:ss"
                                                )
                                                : "-"}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                            {row.registerDate
                                                ? format(
                                                    new Date(row.registerDate),
                                                    "dd.MM.yyyy HH:mm:ss"
                                                )
                                                : "-"}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                            {row.isActive ? (
                                                <Badge variant="success">Aktiv</Badge>
                                            ) : (
                                                <Badge variant="danger">Deaktiv</Badge>
                                            )}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                            {row.confirmed ? (
                                                <Badge variant="success">Təsdiqlənmiş</Badge>
                                            ) : (
                                                <Badge variant="danger">Təsdiqlənməmiş</Badge>
                                            )}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                            {row.birthDate && row.birthDate !== '0001-01-01T00:00:00'
                                                ? format(
                                                    new Date(row.birthDate),
                                                    "dd.MM.yyyy"
                                                )
                                                : "-"}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                            {row.playerBalance}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            className={classes.tableCell}
                                        >
                                            <Tooltip title="Bet history" placement="top">
                                                <button
                                                    aria-label="view"
                                                    className="btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info mr-2"
                                                    onClick={() => {
                                                        window.open(`#/bet-history?phoneNumber=${row.phoneNumber}`, "_blank")
                                                    }}
                                                >
                                                    <EyeIcon />
                                                </button>
                                            </Tooltip>
                                            <RenderIf condition={Customer_ChangeActiveStatus}>
                                                <Tooltip
                                                    title={row.isActive ? "Deaktiv et" : "Aktivləşdir"}
                                                    placement="top"
                                                >
                                                    <button
                                                        aria-label="edit"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-secondary mr-2"
                                                        onClick={() => {
                                                            changeActiveStatus(row.id);
                                                        }}
                                                    >
                                                        {row.isActive ? (
                                                            <span
                                                                className="active-user"><PowerSettingsNewIcon/></span>
                                                        ) : (
                                                            <span
                                                                className="deactive-user"><PowerSettingsNewIcon/></span>
                                                        )}
                                                    </button>
                                                </Tooltip>
                                            </RenderIf>
                                            <RenderIf condition={Change_Customer_Info}>
                                                <Tooltip title="OTP sayı" placement="top">
                                                    <button
                                                        aria-label="Otp sayı"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-success btn-hover-success mr-2"
                                                        onClick={() => {
                                                            setChooseGroupId(row.id);
                                                            setShowCreate(true);
                                                        }}
                                                    >
                                                        <EditIcon/>
                                                    </button>
                                                </Tooltip>
                                            </RenderIf>
                                            <RenderIf condition={Change_Customer_Info}>
                                                <Tooltip title="Doğum tarixi" placement="top">
                                                    <button
                                                        aria-label="Doğum tarixi"
                                                        className="btn btn-sm btn-icon btn-bg-light btn-text-success btn-hover-success mr-2"
                                                        onClick={() => {
                                                            setChooseGroupId(row.id);
                                                            setShowChangeBirthDate(true);
                                                            setBirthDate(row.birthDate)
                                                        }}
                                                    >
                                                        <DateRangeIcon/>
                                                    </button>
                                                </Tooltip>
                                            </RenderIf>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                ) : !groups.length && !isLoading && !isError ? (
                    <AlertComponent variant="info" message="Məlumat tapılmadı."/>
                ) : (
                    !isLoading &&
                    isError &&
                    !isLoading &&
                    isError && (
                        <AlertComponent
                            variant="danger"
                            message="Şəbəkəylə bağlantı kəsildi."
                        />
                    )
                )}
            </div>
            {totalCount > 10 && (
                <ThemeProvider theme={theme}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next Page",
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </ThemeProvider>
            )}
        </div>
    );
}
