import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {login} from "../_redux/authCrud";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {InputAdornment, IconButton} from "@material-ui/core";
import {Input} from "../../../components";
import {Person, Password} from "app/components/Icons";
import {Button} from "app/components";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/
// email: "admin@demo.com",
//   password: "demo",
const initialValues = {
    email: "",
    password: "",
};

function Login(props) {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required("İstifadəçi adı mütləq qeyd olunmalıdır*"),
        password: Yup.string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
                "Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır."
            )
            .required("Şifrə mütləq qeyd olunmalıdır*"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const handleClickShowPassword = () => {
        setShowPassword(prevState => !prevState)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            enableLoading();
            setTimeout(() => {
                login(values.email, values.password)
                    .then(({data: {token, refreshToken}}) => {
                        disableLoading();
                        props.login(token, refreshToken);
                        props.requestUser();
                    })
                    .catch((err) => {
                        disableLoading();
                        setSubmitting(false);
                        err.response !== undefined
                            ? setStatus(err.response.data.message)
                            : setStatus("Xəta baş verdi.");
                    });
            }, 1000);
        },
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    Giriş Hesabı
                </h3>
                <p className="text-muted font-weight-bold">
                    İstifadəçi adı və şifrəni daxil edin.
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                            {formik.status.includes("was not found")
                                ? "E-poçt tapılmadı."
                                : formik.status === "Password is wrong"
                                    ? "Şifrə səhvdir."
                                    : formik.status === "phoneNumber:This field's min length is 12"
                                        ? "Telefon nömrəsi minumum 12 rəqəmdən ibarət olmalıdır."
                                        : formik.status}
                        </div>
                    </div>
                )}

                <div className="form-group fv-plugins-icon-container">
                    <Input
                        placeholder="İstifadəçi adı"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person/>
                                </InputAdornment>
                            )
                        }}
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <Input
                        placeholder="Şifrə"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Password/>
                                </InputAdornment>
                            )
                        }}
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <Button type="submit" disabled={formik.isSubmitting} className="w-100 py-5">
                        <span>Daxil ol</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </Button>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
