import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import RenderIf from "app/components/RenderIf";
import {updateVoucher} from "../requests";
import {DateTimePicker} from "../../../components/DatePicker";
import dayjs from "dayjs";

export default function UpdateVoucher({show, onHide, voucherId, onGroups, skip, take, orderBy, sortField, filterInputs}) {
    const [error, setError] = useState("");
    const [expirationDate, setExpirationDate] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateVoucher({
                id: voucherId,
                expirationDate
            });
            await onGroups({skip, take, orderBy, sortField, ...filterInputs});
            onHide(false);
        } catch (err) {
            setError(err?.response?.message ?? "An error occurred")
        }
    }

    return (
        <Modal
            size="md"
            aria-labelledby="create"
            centered
            className="modal pr-0"
            show={show}
            onHide={onHide}
        >
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Voucheri yenilə</Modal.Title>
                </Modal.Header>
                <div className="w-100 d-flex justify-content-center py-4">
                    <DateTimePicker
                        showTime
                        value={expirationDate ? dayjs(expirationDate) : ""}
                        onChange={e => setExpirationDate(e.replace(" ", "T"))}
                    />
                </div>
                <RenderIf condition={Boolean(error)}>
                    <Modal.Body>
                        <p className="text-danger">{error}</p>
                    </Modal.Body>
                </RenderIf>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Bağla
                    </Button>
                    <Button type="submit">Yenilə</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
