import React, {useEffect, useState} from "react";
import DataTables from "./components/VouchersTable";
import UpdateVoucher from "./modals/UpdateVoucher";
import CancelVoucher from "./modals/CancelVoucher";
import ChangePassword from "./modals/ChangePassword";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import Filter from "./modals/Filter";
import UpdateSales from "./modals/UpdateSales";
import UpdatePayment from "./modals/UpdatePayment";
import Info from "./modals/Info";
import Reprint from "./modals/Reprint";
import axios from "axios";
import {updateObject} from "../../../helpers/updateObject";
import {objectToQueryString} from "../../../_metronic/_helpers";
import RenderIf from "../../components/RenderIf";
import {makeStyles} from "@material-ui/core";
import {PaymentIcon, SalesIcon} from "../../components/Icons";
import {Input, Select} from "../../components";
import dayjs from "dayjs";
import {DateTimePicker} from "../../components/DatePicker";
import {getAgents} from "./requests";
import { Button } from "react-bootstrap";

const useStyles = makeStyles({
    redButton: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #E2241B",
        marginInline: "4px",
        padding: "6px 12px",
        color: "#E2241B",
        fontWeight: 500,
        fontSize: "14px",
        '& svg': {
            fill: "#E2241B",
            marginRight: "6px"
        }
    },
    greenButton: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #40C4BF",
        marginInline: "4px",
        padding: "6px 12px",
        color: "#40C4BF",
        fontWeight: 500,
        fontSize: "14px",
        '& svg': {
            fill: "#40C4BF",
            marginRight: "6px"
        }
    }
})

const statusOptions = [
    {
        label: "Hamısı",
        value: ""
    },
    {
        label: "Pending",
        value: 1
    },
    {
        label: "Read",
        value: 2
    },
    {
        label: "Cancel",
        value: 3
    },
]

function Vouchers() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("createdDateTime");
    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalSum, setTotalSum] = useState(0);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showUpdateVoucher, setShowUpdateVoucher] = useState(false);
    const [showCancelVoucher, setShowCancelVoucher] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showUpdateSales, setShowUpdateSales] = useState(false);
    const [showUpdatePayment, setShowUpdatePayment] = useState(false);
    const [showReprint, setShowReprint] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [info, setInfo] = useState({});
    const [cities, setCities] = useState([]);
    const [regions, setRegions] = useState([]);
    const [agentOptions, setAgentOptions] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState("");
    const [filterInputs, setFilterInputs] = useState({
        agentNumber: "",
        voucherNumber: "",
        customerNumber: "",
        createdDateTime: "",
        startDate: "",
        endDate: ""
    });

    const isFrozenBySystem = groups[0]?.isFrozenBySystem;
    const isPaymentBlocked = groups[0]?.isPaymentBlocked;

    const handleReset = () => {
        setFilterInputs({
            agentNumber: "",
            voucherNumber: "",
            customerNumber: "",
            createdDateTime: "",
            startDate: "",
            endDate: ""
        })
    }

    function handleChange() {
        setShowFilter((key) => !key);
    }

    const onGroups = (filter = {}) => {
        setIsLoading(true);

        const {isActive, ...rest} = filter;

        const query = objectToQueryString({...rest, isActive: isActive === 'all' ? "" : isActive, agentNumbers: selectedAgents});

        axios
            .get(`vouchers/filter?${query}`)
            .then(({data: {data, totalCount, totalSum}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setTotalSum(totalSum)
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const onGroupChoose = (id) => {
        return axios.get(`agents/byId?id=${id}`);
    };

    const getCities = () => {
        setIsLoading(true)
        axios.get(`cities/filter`)
            .then(({data: {data}}) => {
                setCities(data)
                setIsLoading(false)
            })
            .catch(() => {
                console.log("error")
            })
    };

    const getRegions = (cityId) => {
        if (cityId !== "") {
            axios.get(`cities/regions?CityId=${cityId}`)
                .then(({data: {data}}) => {

                    setRegions(data)
                })
                .catch(() => {
                    console.log("error")
                })
        } else {
            setRegions([])
        }
    }

    const downloadExcel = async () => {
        const query = objectToQueryString({...filterInputs, agentNumbers: selectedAgents});

        try {
            const {data} = await axios({
                url: `vouchers/filter?${query}`,
                method: "GET",
                responseType: "blob",
                headers: {
                    "X-Content-Type": "application/xlsx"
                    }
            });
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Vouchers.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
        }
    }

    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [filterInputs, skip, take, orderBy, sortField, selectedAgents]);

    useEffect(() => {
        getAgents()
            .then(({data}) => {
                const agentOptions = data?.data?.map(agent => ({
                    label: agent.number,
                    value: agent.number
                }));
                setAgentOptions(agentOptions ?? [])
            })
            .catch(() => {
            })
    }, [])

    const classes = useStyles();

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center pb-4">
                <h2 className="mb-0 font-weight-bolder">Vouchers</h2>
                <div className="d-flex pl-15">
                    <span className="mr-4 text-nowrap">Count: {totalCount}</span>
                    <span className="mr-4 text-nowrap">Total amount: {totalSum} AZN</span>
                </div>
                <Button onClick={downloadExcel} variant="outline-success" className="ml-auto">Download excel</Button>
                <RenderIf
                    condition={typeof isFrozenBySystem !== 'undefined' || typeof isPaymentBlocked !== 'undefined'}>
                    <div className="w-100 d-flex justify-content-end">
                        <RenderIf condition={typeof isFrozenBySystem !== 'undefined'}>
                            <button
                                style={{padding: "6px 12px", fontSize: "11px", whiteSpace: "nowrap"}}
                                onClick={() => setShowUpdateSales(true)}
                                className={isFrozenBySystem ? classes.greenButton : classes.redButton}
                            >
                                <SalesIcon/>{isFrozenBySystem ? "Satışı aktivləşdir" : "Satışı dayandır"}
                            </button>
                        </RenderIf>
                        <RenderIf condition={typeof isPaymentBlocked !== 'undefined'}>
                            <button
                                style={{padding: "6px 12px", fontSize: "11px", whiteSpace: "nowrap"}}
                                onClick={() => setShowUpdatePayment(true)}
                                className={isPaymentBlocked ? classes.greenButton : classes.redButton}
                            >
                                <PaymentIcon/>
                                {isPaymentBlocked ? "Uduş ödənişini aktivləşdir" : "Uduş ödənişini dayandır"}
                            </button>
                        </RenderIf>
                    </div>
                </RenderIf>
            </div>
            <div className="d-flex w-100">
                <Input
                    name="agentNumber"
                    value={filterInputs.agentNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Agent"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="voucherNumber"
                    value={filterInputs.voucherNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Voucher"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="customerNumber"
                    value={filterInputs.voucherNumbercustomerNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Mobile"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <DateTimePicker
                    showTime
                    placeholder="Yaradılma tarixi"
                    className="mr-2"
                    value={filterInputs.createdDateTime ? dayjs(filterInputs.createdDateTime) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, createdDateTime: value.replace(" ", "T")
                    }))}
                />
                <DateTimePicker
                    placeholder="Başlanğıc tarixi"
                    className="mr-2"
                    value={filterInputs.startDate ? dayjs(filterInputs.startDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, startDate: value
                    }))}
                />
                <DateTimePicker
                    placeholder="Bitmə tarixi"
                    className="mr-2"
                    value={filterInputs.endDate ? dayjs(filterInputs.endDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, endDate: value
                    }))}
                />
                <Select
                    placeholder="Status"
                    options={statusOptions} onChange={(e) => setFilterInputs(prevState => ({
                    ...prevState, statusId: e.value
                }))}
                    className="mr-2"
                />
                <Select
                    placeholder="Agent numbers"
                    options={agentOptions}
                    onChange={(agentList) => {
                        setSelectedAgents(agentList?.map(agent => agent.value)?.join(",") ?? "")
                    }}
                    isMulti
                />
            </div>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowUpdateVoucher={setShowUpdateVoucher}
                setShowCancelVoucher={setShowCancelVoucher}
                setShowReprint={setShowReprint}
                setShowInfo={setShowInfo}
                setInfo={setInfo}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                onGroups={onGroups}
                skip={skip}
                setSkip={setSkip}
                setTake={setTake}
                setOrderBy={setOrderBy}
                setSortField={setSortField}
                take={take}
                orderBy={orderBy}
                sortField={sortField}
                totalCount={totalCount}
                setShowChangePassword={setShowChangePassword}
                filterInputs={filterInputs}
            />
            {showCreate && (
                <Create
                    show={showCreate}
                    onHide={setShowCreate}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                    filterInputsUpdate={filterInputsUpdate}
                    cities={cities}
                    regions={regions}
                    setRegions={setRegions}
                    setCities={setCities}
                    getCities={getCities}
                    getRegions={getRegions}
                />
            )}

            {showUpdateVoucher && (
                <UpdateVoucher
                    show={showUpdateVoucher}
                    onHide={setShowUpdateVoucher}
                    onGroups={onGroups}
                    voucherId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            {showCancelVoucher && (
                <CancelVoucher
                    show={showCancelVoucher}
                    onHide={setShowCancelVoucher}
                    onGroups={onGroups}
                    voucherId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            {showChangePassword && (
                <ChangePassword
                    id={chooseGroupId}
                    show={showChangePassword}
                    onHide={setShowChangePassword}
                />
            )}
            {showRemove && (
                <Delete
                    id={chooseGroupId}
                    show={showRemove}
                    onHide={setShowRemove}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            <Filter
                show={showFilter}
                onHide={handleChange}
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs}
                resetFilter={handleReset}
            />
            {
                showUpdateSales && (
                    <UpdateSales
                        filterInputs={filterInputs}
                        skip={skip}
                        take={take}
                        show={showUpdateSales}
                        onHide={setShowUpdateSales}
                        onGroups={onGroups}
                        orderBy={orderBy}
                        sortField={sortField}
                        isFrozenBySystem={isFrozenBySystem}
                    />
                )
            }
            {
                showUpdatePayment && (
                    <UpdatePayment
                        filterInputs={filterInputs}
                        skip={skip}
                        take={take}
                        show={showUpdatePayment}
                        onHide={setShowUpdatePayment}
                        onGroups={onGroups}
                        orderBy={orderBy}
                        sortField={sortField}
                        isPaymentBlocked={isPaymentBlocked}
                    />
                )
            }
            {showInfo && (
                <Info show={showInfo} onHide={setShowInfo} info={info}/>
            )}
             {showReprint && (
                <Reprint
                    id={chooseGroupId}
                    show={showReprint}
                    onHide={setShowReprint}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
        </div>
    );
}

export default Vouchers;
