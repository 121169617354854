import React from "react";
import { Modal, Button } from "react-bootstrap";
import format from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  line: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px dashed black",
    padding: "8px 0"
  },
});

export default function Info({ onHide, show, info }) {
  const classes = useStyles();

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="info" centered>
      <Modal.Header closeButton>
        <Modal.Title id="delete">Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={classes.line}>
          <span>ID:</span> <span>{info.id}</span>
        </div>
        <div className={classes.line}>
          <span>Voucher number:</span> <span>{info.voucherNumber}</span>
        </div>
        <div className={classes.line}>
          <span>Agent:</span> <span>{info.agentNumber}</span>
        </div>
        <div className={classes.line}>
          <span>Created:</span>{" "}
          <span>
            {info.createdDateTime
              ? format(new Date(info.createdDateTime), "dd.MM.yyyy HH:mm:ss")
              : ""}
          </span>
        </div>
        <div className={classes.line}>
          <span>Expiration:</span>{" "}
          <span>
            {info.expirationDate
              ? format(new Date(info.expirationDate), "dd.MM.yyyy HH:mm:ss")
              : ""}
          </span>
        </div>
        <div className={classes.line}>
          <span>Use date:</span>{" "}
          <span>
            {info.useDate
              ? format(new Date(info.useDate), "dd.MM.yyyy HH:mm:ss")
              : ""}
          </span>
        </div>
        <div className={classes.line}>
          <span>Updated date:</span>{" "}
          <span>
            {info.updatedDateTime
              ? format(new Date(info.updatedDateTime), "dd.MM.yyyy HH:mm:ss")
              : ""}
          </span>
        </div>
        <div className={classes.line}>
          <span>Updated by:</span> <span>{info.updated_by_name}</span>
        </div>
        <div className={classes.line}>
          <span>Price:</span> <span>{info.price} AZN</span>
        </div>
        <div className={classes.line}>
          <span>Status:</span> <span>{info.statusString}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={() => onHide(false)}>
          Bağla
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
