import axios from "axios";

const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}agents/changePassword`;
const UPDATE_TEST_AGENT_URL = `${process.env.REACT_APP_API_URL}agents/updateTestAgent`;

const changePassword = (body) => (
    axios.put(CHANGE_PASSWORD_URL, body)
)

const updateTestAgent = (query) => (
    axios.put(`${UPDATE_TEST_AGENT_URL}?${query}`,)
)

export {changePassword, updateTestAgent}