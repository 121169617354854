import React, { useState, useEffect } from "react";
import axios from "axios";
import AlertComponent from "_metronic/_helpers/AlertComponent";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import Table from "./components/Table";
import { Button } from "react-bootstrap";
import { objectToQueryString } from "../../../_metronic/_helpers";
import { DateTimePicker } from "../../components/DatePicker";
import dayjs from "dayjs";
import { Select, Input } from "app/components";

const date = new Date();

const initialStartDate =
  new Date(date.getFullYear(), date.getMonth(), date.getDate())
    .toISOString()
    .split("T")[0] + "T00:00";
const initialEndDate = date.toISOString().split("T")[0] + "T23:59";

const options = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Hide",
    value: true,
  },
  {
    label: "Show",
    value: false,
  },
];

export default function SaleReport() {
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [orderBy, setOrderBy] = useState(false);
  const [sortField, setSortField] = useState("placementDateTime");
  const [hideTestAgents, setHideTestAgents] = useState("");
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [agentNumber, setAgentNumber] = useState("");
  const [state, setState] = useState({
    isLoading: false,
    error: null,
    data: null,
  });

  async function getData() {
    const query = objectToQueryString({
      sortField,
      orderBy,
      hideTestAgents,
      startDate,
      endDate,
      agentNumber
    });

    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const { data } = await axios({
        method: "GET",
        url: `reports/sale_report?${query}`,
      });
      setState({
        isLoading: false,
        error: null,
        data,
      });
    } catch (err) {
      setState({
        isLoading: false,
        error: err?.response?.data,
        data: null,
      });
    }
  }

  async function getExcel() {
    const query = objectToQueryString({
      orderBy,
      sortField,
      startDate,
      endDate,
      hideTestAgents,
      agentNumber
    });

    try {
      const { data } = await axios({
        url: `reports/sale_report?${query}`,
        method: "GET",
        headers: {
          "X-Content-Type": "application/xlsx",
        },
        responseType: "blob",
      });
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "TicketReport.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {}
  }

  function handleChange(event) {
    setHideTestAgents(event.value);
  }

  function handleChangeStartDate(e) {
    setStartDate(e.replace(" ", "T").slice(0, e.length - 3));
  }

  function handleChangeEndDate(e) {
    setEndDate(e.replace(" ", "T").slice(0, e.length - 3));
  }

  useEffect(() => {
    getData();
  }, [orderBy, sortField, skip, take, hideTestAgents, startDate, endDate, agentNumber]);

  return (
    <div className="row bg-white rounded">
      <div className="col-12 d-flex align-items-center pb-4">
        <h2 className="mb-0 font-weight-bolder">Sale report</h2>
        <div className="d-flex pl-15">
          <span className="mr-4 text-nowrap">Count: {state.data?.count}</span>
        </div>
        <RenderIf condition={state?.data?.data?.length}>
          <Button
            onClick={getExcel}
            variant="outline-success"
            className="ml-auto"
          >
            Download excel
          </Button>
        </RenderIf>
      </div>
      <div className="col-12 py-4 overflow-auto">
        <div className="d-flex w-100">
          <Input
            name="number"
            value={agentNumber}
            onChange={(e) => setAgentNumber(e.target.value)}
            placeholder="Agent number"
            className="form-control form-control-solid py-1 px-6 mr-2"
            style={{ width: "200px" }}
          />
          <Select
            placeholder="Hide test agents"
            options={options}
            onChange={handleChange}
            className="mr-2"
          />
          <DateTimePicker
            showTime
            value={startDate ? dayjs(startDate) : ""}
            onChange={handleChangeStartDate}
            className="mr-2"
          />
          <DateTimePicker
            showTime
            value={endDate ? dayjs(endDate) : ""}
            onChange={handleChangeEndDate}
          />
        </div>
        <RenderIf condition={state.data?.data?.length}>
          <Table
            data={state.data}
            isLoading={state.isLoading}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            sortField={sortField}
            setSortField={setSortField}
            take={take}
            setSkip={setSkip}
            setTake={setTake}
          />
        </RenderIf>
        <RenderIf condition={state.isLoading}>
          <div className="d-flex justify-content-center">
            <Loading />
          </div>
        </RenderIf>
        <RenderIf
          condition={!state.isLoading && state.data?.reportList?.length === 0}
        >
          <AlertComponent message="Məlumat tapılmadı" variant="info" />
        </RenderIf>
        <RenderIf condition={!state.isLoading && state.error}>
          <AlertComponent message="Xəta baş verdi" variant="danger" />
        </RenderIf>
      </div>
    </div>
  );
}
