import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";

import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { getRoles } from "../_redux/usersCrud";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {Button} from "../../../../app/components";

const initialValues = {
  name: "",
  surName: "",
  userName: "",
  email: "",
  phoneNumber: "",
  password: "",
  roleId: "",
};

export default function Create(props) {
  const token = useSelector((state) => state.auth.token);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleClickShowPasswordTwo = () => {
    setShowPasswordTwo((prevState) => !prevState);
  };
  const { show, onHide, onGroups, skip, take, orderBy } = props;

  const [isError, setIsError] = useState("");
  const [roles, setRoles] = useState([]);

  const CreateSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Ən az 3 hərf olmalıdır.")
      .max(255, "Ən çox 255 hərf ola bilər.")
      .required("Ad tələb olunur."),
    surName: Yup.string()
      .min(4, "Ən az 4 hərf olmalıdır.")
      .max(255, "Ən çox 255 hərf ola bilər.")
      .required("Soyad tələb olunur."),
    email: Yup.string()
      .email("Email formatı səhvdir. Örnək: jhon.doe@example.com")
      .required("Email tələb olunur."),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_-])(?=.{8,})/,
        "Şifrə böyük hərf, kiçik hərf, rəqəm və xarakterdən(!@#$%^&*()_-) ibarət olmalıdır."
      )
      .required("Şifrə tələb olunur"),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Hər iki şifrə eyni olmalıdır."
        ),
      })
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{8,})/,
        "Şifrə ən az bir böyük hərfdən, kiçik hərfdən, rəqəm və simvoldan ibarət olmalıdır."
      )
      .required("Yeni şifrənin təsdiqi tələb olunur."),
    roleId: Yup.string().required("Role  tələb olunur."),
  });

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const onPostGroup = (values, resetForm, setSubmitting) => {
    const group = {
      name: values.name,
      surname: values.surName,
      email: values.email,
      password: values.password,
      roleId: values.roleId,
    };
    axios({
      method: "post",
      url: `users`,
      data: group,
      headers: {
        Authorization: `bearer ${token}`,
      },
    })
      .then(() => {
        onGroups(null, skip, take, orderBy);
        setSubmitting(false);
        resetForm();
        onHide(false);
      })
      .catch((err) => {
        let message =
          typeof err.response !== "undefined"
            ? err.response.data.message
            : err.message;
        setIsError(message);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostGroup(values, resetForm, setSubmitting);
    },
  });

  const resetForm = () => {
    onHide();
    formik.resetForm();
  };

  useEffect(() => {
    setIsError("");
  }, [show]);

  useEffect(() => {
    getRoles()
      .then(({ data }) => {
        const arr = [...data];
        setRoles(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal
      size="md"
      aria-labelledby="create"
      centered
      className="modal pr-0"
      show={show}
      onHide={resetForm}
      backdrop="static"
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">İstifadəçi yarat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-6 col-md-6 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {isError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block MuiFormLabel-root Mui-error">
                        {isError}
                      </div>
                    </div>
                  )}
                  <TextField
                    id="name"
                    name="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                  />
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-6 col-md-6 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {isError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block MuiFormLabel-root Mui-error">
                        {isError}
                      </div>
                    </div>
                  )}
                  <TextField
                    id="surnname"
                    name="surnname"
                    label="Soyad"
                    placeholder="Soyad"
                    className="w-100"
                    error={getInputClasses("surName")}
                    {...formik.getFieldProps("surName")}
                  />
                </div>
                {formik.touched.surName && formik.errors.surName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.surName}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {isError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block MuiFormLabel-root Mui-error">
                        {isError}
                      </div>
                    </div>
                  )}
                  <FormControl
                    error={getInputClasses("roleId")}
                    className="w-100"
                    placeholder="roleId"
                  >
                    <InputLabel id="status">Role</InputLabel>
                    <Select
                      {...formik.getFieldProps("roleId")}
                      labelId="roleId"
                      id="roleId"
                    >
                      {roles.map((role, index) => (
                        <MenuItem key={index} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.roleId && formik.errors.roleId ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.roleId}
                        </div>
                      </div>
                    ) : null}
                  </FormControl>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {isError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block MuiFormLabel-root Mui-error">
                        {isError}
                      </div>
                    </div>
                  )}
                  <TextField
                    id="email"
                    name="email"
                    label="Email adresi"
                    placeholder="Email adresi"
                    className="w-100"
                    error={getInputClasses("email")}
                    {...formik.getFieldProps("email")}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {isError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block MuiFormLabel-root Mui-error">
                        {isError}
                      </div>
                    </div>
                  )}
                  <TextField
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    label="Şifrə"
                    placeholder="Şifrə"
                    className="w-100"
                    error={getInputClasses("password")}
                    {...formik.getFieldProps("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {isError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block MuiFormLabel-root Mui-error">
                        {isError}
                      </div>
                    </div>
                  )}
                  <TextField
                    type={showPasswordTwo ? "text" : "password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Şifrə təkrar"
                    placeholder="Şifrə təkrar"
                    className="w-100"
                    error={getInputClasses("confirmPassword")}
                    {...formik.getFieldProps("confirmPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordTwo}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordTwo ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.confirmPassword}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="w-100 border-0 py-4" style={{backgroundColor: "#00AFF5"}}>+ İstifadəçi yarat</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
