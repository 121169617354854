import React, {useEffect, useState} from "react";
import DataTables from "./components/RolesTable";
import Edit from "./modals/Edit";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import View from "./modals/View";
import axios from "axios";
import {Button} from "../../../app/components";

function Products() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalCount] = useState(0);
    const [sortField, setSortField] = useState("");
    const [orderBy, setOrderBy] = useState(false);
    const [groups, setGroups] = useState([]);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showView, setShowView] = useState(false);


    const onGroups = () => {

        setIsLoading(true);
        axios
            .get(`roles`)
            .then(({data}) => {
                setIsLoading(false);
                setGroups(data);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };


    const onGroupChoose = () => {
        return axios.get(`roles/getByIdForUpdate?id=${chooseGroupId}`);
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [skip, take, orderBy, sortField]);

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center">
                <h2 className="mb-0 font-weight-bolder">Rollar</h2>
                <Button
                    onClick={() => setShowCreate(true)}
                    className="ml-auto mb-0 border-0 text-dark"
                    style={{backgroundColor: "#00aff540", padding: "6px 12px", fontSize: "11px"}}
                >
                    + Yeni rol yarat
                </Button>
            </div>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowEdit={setShowEdit}
                setShowView={setShowView}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                sortField={sortField}
                setSortField={setSortField}
                setOrderBy={setOrderBy}
                setSkip={setSkip}
                setTake={setTake}
                take={take}
                orderBy={orderBy}
                totalCount={totalCount}
            />
            {showCreate && (
                <Create
                    show={showCreate}
                    onHide={setShowCreate}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                />
            )}

            {showEdit && (
                <Edit
                    show={showEdit}
                    onHide={setShowEdit}
                    onGroups={onGroups}
                    chooseGroupId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    id={chooseGroupId}
                />
            )}

            {showRemove && (
                <Delete
                    id={chooseGroupId}
                    show={showRemove}
                    onHide={setShowRemove}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                />
            )}
            {showView && (
                <View show={showView} onHide={setShowView} roleId={chooseGroupId}/>
            )}
        </div>
    );
}

export default Products;
