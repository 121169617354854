import Axios from "axios";
import * as auth from "../app/modules/Auth/_redux/authRedux";

const REFRESH_TOKEN_URL = `users/refresh`;

export const getOwnerRefreshToken = (refreshToken) => {
    return Axios.post(REFRESH_TOKEN_URL, refreshToken)
}

export default function setupAxios(axios, store) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.interceptors.request.use(
        (config) => {
            const {
                auth: {token},
            } = store.getState();

            if (token) {
                config.headers.Authorization = `bearer ${token}`;
            }

            return config;
        },
        (err) => Promise.reject(err)
    );
    axios.interceptors.response.use(
        (response) => {
            return response;
        }, async (error) => {
            const {dispatch, getState} = store;
            const {actions: {setRefreshToken, logout}} = auth
            const response = error.response;
            const originalRequest = error.config;

            const {
                auth: {refreshToken},
            } = getState();
            if (response) {

                if (
                    response.status === 400 && response.data.message === "Token is not correct."
                ) {
                    dispatch(logout());
                } else if (response.status === 401) {
                    const refreshData = {
                        refreshToken
                    }

                    const refreshResponse = await getOwnerRefreshToken(refreshData);
                    if (refreshResponse.status === 200) {
                        const {data: {token, refreshToken, expiredDate}} = refreshResponse;
                        dispatch(setRefreshToken(token, refreshToken, expiredDate));
                        originalRequest.headers.Authorization = `Bearer ${token}`
                        return await axios(originalRequest);
                    } else {
                        dispatch(logout());
                    }
                }
            }
            return Promise.reject(error);
        }
    );
}
