import React, {useEffect, useState} from "react";
import DataTables from "./components/CustomersTable";
import Filter from "./modals/Filter";
import axios from "axios";
import {objectToQueryString} from "../../../_metronic/_helpers";
import {updateObject} from "../../../helpers/updateObject";
import CountPermissions from "./modals/CountPermissions";
import ChangeBirthDate from "./modals/ChangeBirthDate";
import RenderIf from "app/components/RenderIf";
import {DateTimePicker} from "../../components/DatePicker";
import {Button, Input, Select} from "../../components";
import dayjs from "dayjs";

const statusOptions = [
    {
        label: "Hamısı",
        value: ""
    },
    {
        label: "Aktiv",
        value: true
    },
    {
        label: "Deaktiv",
        value: false
    }
];

const confirmationOptions = [
    {
        label: "Hamısı",
        value: ""
    },
    {
        label: "Təsdiqlənmiş",
        value: true
    },
    {
        label: "Təsdiqlənməmiş",
        value: false
    },
]


function Products() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [sortField, setSortField] = useState("registerDate");
    const [orderBy, setOrderBy] = useState(false);
    const [groups, setGroups] = useState([]);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);

    const [showCreate, setShowCreate] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showChangeBirthDate, setShowChangeBirthDate] = useState(false);
    const [birthDate, setBirthDate] = useState("");

    const [filterInputs, setFilterInputs] = useState({
        phoneNumber: "",
        registerStartDate: "",
        registerDate: "",
        isActive: "",
        confirmed: "",
        registerEndDate: ""
    });

    const handleReset = () => {
        setFilterInputs({
            phoneNumber: "",
            registerStartDate: "",
            registerDate: "",
            isActive: "",
            confirmed: "",
            registerEndDate: ""
        });
    };

    function handleChange() {
        setShowFilter((key) => !key);
    }

    function handleChangeCount() {
        setShowCreate((key) => !key);
    }

    const onGroups = (filter = {}) => {

        const query = objectToQueryString(filter);
        setIsLoading(true);
        axios
            .get(`customers/filter?${query}`)
            .then(({data: {data, totalCount}}) => {
                setIsLoading(false);
                setGroups(data);
                setIsError(false);
                setTotalCount(totalCount);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const downloadExcel = async () => { 
        const query = objectToQueryString(filterInputs);

        try {
            const {data} = await axios({
                url: `customers/filter?${query}`,
                method: "GET",
                responseType: "blob",
                headers: {
                    "X-Content-Type": "application/xlsx"
                    }
            });
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Customers.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
        }
    }

    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value,
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500);

        return () => {
            clearTimeout(getAllTimer);
        };
    }, [filterInputs, skip, take, orderBy, sortField]);

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center pb-4">
                <h2 className="mb-0 font-weight-bolder">Müştərilər</h2>
                <div className="d-flex pl-15">
                    <span className="mr-4 text-nowrap">Total: {totalCount}</span>
                </div>
                <Button onClick={downloadExcel} variant="outline-success" className="ml-auto">Download excel</Button>
            </div>
            <div className="d-flex w-100">
                <Input
                    name="phoneNumber"
                    value={filterInputs.phoneNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Telefon nömrəsi"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <DateTimePicker
                    placeholder="Başlanğıc tarixi"
                    className="mr-2"
                    value={filterInputs.registerStartDate ? dayjs(filterInputs.registerStartDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, registerStartDate: value
                    }))}
                />
                <DateTimePicker
                    placeholder="Bitmə tarixi"
                    className="mr-2"
                    value={filterInputs.registerEndDate ? dayjs(filterInputs.registerEndDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, registerEndDate: value
                    }))}
                />
                <DateTimePicker
                    showTime
                    placeholder="Qeydiyyat tarixi"
                    className="mr-2"
                    value={filterInputs.registerDate ? dayjs(filterInputs.registerDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, registerDate: value.replace(" ", "T")
                    }))}
                />
                <Select
                    options={statusOptions}
                    defaultValue={statusOptions[0]}
                    className="mr-2"
                    onChange={e => setFilterInputs(prevState => ({
                        ...prevState, isActive: e.value
                    }))}
                />
                <Select
                    options={confirmationOptions}
                    defaultValue={confirmationOptions[0]}
                    className="mr-2"
                    onChange={e => setFilterInputs(prevState => ({
                        ...prevState, confirmed: e.value
                    }))}
                />
            </div>
            <DataTables
                groups={groups}
                onGroups={onGroups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowChangeBirthDate={setShowChangeBirthDate}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                sortField={sortField}
                setSortField={setSortField}
                setOrderBy={setOrderBy}
                setSkip={setSkip}
                skip={skip}
                setTake={setTake}
                take={take}
                orderBy={orderBy}
                totalCount={totalCount}
                setBirthDate={setBirthDate}
            />

            <Filter
                show={showFilter}
                onHide={handleChange}
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs}
                resetFilter={handleReset}
                setFilterInputs={setFilterInputs}
            />
            <RenderIf condition={showCreate}>
                <CountPermissions
                    chooseGroupId={chooseGroupId}
                    show={showCreate}
                    onHide={handleChangeCount}
                    onGroups={onGroups}
                    filterInputsUpdate={filterInputsUpdate}
                    filterInputs={filterInputs}
                    resetFilter={handleReset}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                />
            </RenderIf>
            <RenderIf condition={showChangeBirthDate}>
                <ChangeBirthDate
                    show={showChangeBirthDate}
                    onHide={setShowChangeBirthDate}
                    customerId={chooseGroupId}
                    birthDate={birthDate}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    onGroups={onGroups}
                    filterInputs={filterInputs}
                />
            </RenderIf>
        </div>
    );
}

export default Products;
