import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
// eslint-disable-next-line no-restricted-imports
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import { makeStyles } from "@material-ui/core";
// eslint-disable-next-line no-restricted-imports
import { azAZ } from "@material-ui/core/locale";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  TableSortLabel,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import { TablePagination } from "@material-ui/core";

const theme = createMuiTheme(azAZ);

const headRows = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: true,
    label: "Order ID",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created at",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Updated at",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: "Amount",
  },
  {
    id: "paymentType",
    numeric: false,
    disablePadding: true,
    label: "Payment type",
  },
  {
    id: "paymentStatus",
    numeric: false,
    disablePadding: true,
    label: "Payment status",
  },
  {
    id: "paymentMethod",
    numeric: false,
    disablePadding: true,
    label: "Payment method",
  },
  {
    id: "pan",
    numeric: false,
    disablePadding: true,
    label: "Pan",
  }, 
  {
    id: "paymentProvider",
    numeric: false,
    disablePadding: true,
    label: "Payment provider",
  },
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  {
    id: "agentNumber",
    numeric: false,
    disablePadding: true,
    label: "Agent number",
  },
  {
    id: "voucherCode",
    numeric: false,
    disablePadding: true,
    label: "Voucher code",
  },
];

const typeBadges = {
  "Deposit": "badge badge-success",
  "Withdraw": "badge badge-warning"
}

const statusBadges = {
  "InProgress": "badge badge-warning",
  "Pending": "badge badge-info",
  "Confirmed": "badge badge-success",
  "Declined": "badge badge-danger",
  "Refunded": "badge badge-secondary",
  "Authorized": "badge badge-light",
  "Cancelled": "badge badge-danger",
  "Timeout": "badge badge-dark"
}

function EnhancedTableHead(props) {
  const { orderBy, sortField, onRequestSort } = props;

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => {
          if (row.id === "operations") {
            return (
              <TableCell
                key={index}
                align={row.numeric ? "right" : "left"}
                className={classes.tableHeadCell}
              >
                <div>{row.label}</div>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                sortDirection={sortField === row.id ? "asc" : false}
                key={index}
                align={row.numeric ? "right" : "left"}
                className={classes.tableHeadCell}
              >
                <TableSortLabel
                  active={sortField === row.id}
                  direction={orderBy ? "desc" : "asc"}
                  onClick={() => {
                    onRequestSort(row.id);
                  }}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "10px",
    borderRadius: "18px",
    background: "#F5F5F5",
    padding: "0 8px 8px 8px",
  },
  tableRow: {
    borderRadius: "18px",
    border: "unset",
    padding: "16px",
    "&:hover": {
      backgroundColor: "unset !important",
    },
  },
  table: {
    minWidth: 750,
    borderCollapse: "separate",
    borderSpacing: "0 4px",
  },
  tableHeadCell: {
    border: "unset",
    padding: "3px",
    "&:first-child": {
      paddingLeft: "10px",
    },
    "&:last-child": {
      paddingRight: "10px",
    },
  },
  tableCell: {
    border: "unset",
    background: "#FFFFFF",
    marginBottom: "16px",
    // padding: "3px",
    padding: "14px 14px 14px 3px",
    "&:first-child": {
      borderRadius: "18px 0 0 18px",
      paddingLeft: "10px",
    },
    "&:last-child": {
      borderRadius: "0 18px 18px 0",
      paddingRight: "10px",
    },
  },
  tableWrapper: {
    overflowX: "auto",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));

export default function EnhancedTable({
  groups,
  isLoading,
  isError,
  deleteGroupsIds,
  setDeleteGroupsIds,
  take,
  orderBy,
  setSortField,
  setOrderBy,
  setSkip,
  setTake,
  sortField,
  totalCount,
}) {
  const classes = useStyles();

  const { Voucher_Update } = useSelector(
    (state) => state.auth.user.permissions,
    shallowEqual
  );

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = groups.map((n) => n.id);
      setDeleteGroupsIds(newSelecteds);
      return;
    }
    setDeleteGroupsIds([]);
  }

  const isSelected = (name) => deleteGroupsIds.indexOf(name) !== -1;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (field) => {
    const isOrder = field === sortField && !orderBy;

    setSortField(field);
    setOrderBy(isOrder);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    setSkip(take * newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setTake(+event.target.value);
    setPage(0);
    setSkip(0);
  }

  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.progresRoot}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
      <div className={classes.tableWrapper}>
        {groups.length ? (
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={deleteGroupsIds.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={groups.length}
              onRequestSort={handleRequestSort}
              sortField={sortField}
              orderBy={orderBy}
            />
            <TableBody>
              {groups.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    className={classes.tableRow}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.createdAt}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.updatedAt}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.amount} AZN
                      {/* {row.expirationDate
                        ? format(
                            new Date(row.expirationDate),
                            "dd.MM.yyyy HH:mm:ss"
                          )
                        : "-"} */}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      <span className={typeBadges[row.paymentType]}>{row.paymentType}</span>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {/* <span
                        className={`badge ${voucherTypeBadges[row.voucherTypeString]}`}
                      > */}
                      <span className={statusBadges[row.paymentStatus]}>{row.paymentStatus}</span>
                      {/* </span> */}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.paymentMethod}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                        {row.pan} 
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.paymentProvider}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.phoneNumber}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.agentNumber}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.voucherCode}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : !groups.length && !isLoading && !isError ? (
          <AlertComponent variant="info" message="Məlumat tapılmadı." />
        ) : (
          !isLoading &&
          isError &&
          !isLoading &&
          isError && (
            <AlertComponent
              variant="danger"
              message="Şəbəkəylə bağlantı kəsildi."
            />
          )
        )}
      </div>
      {totalCount > 10 && (
        <ThemeProvider theme={theme}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      )}
    </div>
  );
}
