import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { HiUsers } from "react-icons/hi";
import { FaTicketAlt, FaUserLock, FaDatabase } from "react-icons/fa";
import { AiOutlinePercentage } from "react-icons/ai";
import { AiOutlineInbox } from "react-icons/ai";
import { HiOutlineTicket } from "react-icons/hi";
import { CgFileDocument } from "react-icons/cg";
import { IoTicketOutline } from "react-icons/io5";
import { GiTrophyCup } from "react-icons/gi";
import LoopIcon from "@material-ui/icons/Loop";
import FaceIcon from "@material-ui/icons/Face";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import { useSelector, shallowEqual } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const permissions = useSelector(
    (state) => state.auth?.user?.permissions,
    shallowEqual
  );

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {permissions?.Admin_View && (
          <li
            className={`menu-item  ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <HiUsers />
              </span>
              <span className="menu-text">İstifadəçilər</span>
            </NavLink>
          </li>
        )}
        {permissions?.Product_View && (
          <li
            className={`menu-item  ${getMenuItemActive("/products", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/products">
              <span className="svg-icon menu-icon">
                <AiOutlineInbox />
              </span>
              <span className="menu-text">Məhsullar</span>
            </NavLink>
          </li>
        )}
        {permissions?.Circulation_View && (
          <li
            className={`menu-item  ${getMenuItemActive(
              "/circulations",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/circulations">
              <span className="svg-icon menu-icon">
                <LoopIcon />
              </span>
              <span className="menu-text">Turlar</span>
            </NavLink>
          </li>
        )}
        {permissions?.Agent_View && (
          <li
            className={`menu-item  ${getMenuItemActive("/agents", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/agents">
              <span className="svg-icon menu-icon">
                <FaceIcon />
              </span>
              <span className="menu-text">Agentlər</span>
            </NavLink>
          </li>
        )}
        {permissions?.Ticket_View && (
          <li
            className={`menu-item  ${getMenuItemActive("/tickets", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/tickets">
              <span className="svg-icon menu-icon">
                <HiOutlineTicket />
              </span>
              <span className="menu-text">Biletlər</span>
            </NavLink>
          </li>
        )}
        {permissions?.View_Void_Ticket && (
          <li
            className={`menu-item  ${getMenuItemActive(
              "/bron-tickets",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/bron-tickets">
              <span className="svg-icon menu-icon">
                <FaTicketAlt />
              </span>
              <span className="menu-text">Bron Et Kuponu</span>
            </NavLink>
          </li>
        )}
        {permissions?.Customer_View && (
          <li
            className={`menu-item  ${getMenuItemActive("/customers", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/customers">
              <span className="svg-icon menu-icon">
                <RecentActorsIcon />
              </span>
              <span className="menu-text">Müştərilər</span>
            </NavLink>
          </li>
        )}
        {permissions?.Role_View && (
          <li
            className={`menu-item  ${getMenuItemActive("/roles", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/roles">
              <span className="svg-icon menu-icon">
                <FaUserLock />
              </span>
              <span className="menu-text">Rollar</span>
            </NavLink>
          </li>
        )}
        {permissions?.Change_Tax && (
          <li
            className={`menu-item  ${getMenuItemActive(
              "/tax-and-commissions",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/tax-and-commissions">
              <span className="svg-icon menu-icon">
                <AiOutlinePercentage />
              </span>
              <span className="menu-text">Vergi & Kommisiyalar</span>
            </NavLink>
          </li>
        )}
        {permissions?.View_Reports && (
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/reports",
                    true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/reports">
            <span className="svg-icon menu-icon">
              <CgFileDocument />
            </span>

                <span className="menu-text">Hesabatlar</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/ticket-report",
                          true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to="/ticket-report">
                  <span className="svg-icon menu-icon ">
                    <CgFileDocument />
                  </span>

                      <span className="menu-text">Bilet hesabatı</span>
                    </NavLink>
                  </li>
                  <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(
                          "/sale-report",
                          true
                      )}`}
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to="/sale-report">
                  <span className="svg-icon menu-icon ">
                    <CgFileDocument />
                  </span>

                      <span className="menu-text">Satış hesabatı</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

        )}
         {
          permissions?.Voucher_View && (
            <>
               <li
              className={`menu-item  ${getMenuItemActive("/vouchers", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/vouchers">
              <span className="svg-icon menu-icon">
                <IoTicketOutline />
              </span>
                  <span className="menu-text">Vouchers</span>
              </NavLink>
          </li>
          <li
              className={`menu-item  ${getMenuItemActive("/winning-vouchers", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/winning-vouchers">
              <span className="svg-icon menu-icon">
                <GiTrophyCup />
              </span>
                  <span className="menu-text">Winning vouchers</span>
              </NavLink>
          </li>
            </>
          )
         }
          <li
              className={`menu-item  ${getMenuItemActive("/bet-history", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/bet-history">
              <span className="svg-icon menu-icon">
                <FaDatabase />
              </span>
                  <span className="menu-text">Bet history</span>
              </NavLink>
          </li>
          <li
              className={`menu-item  ${getMenuItemActive("/orders", false)}`}
              aria-haspopup="true"
          >
              <NavLink className="menu-link" to="/orders">
              <span className="svg-icon menu-icon">
                <CreditCardIcon />
              </span>
                  <span className="menu-text">Transactions</span>
              </NavLink>
          </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
