import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import RenderIf from "app/components/RenderIf";
import {cancelVoucher} from "../requests";

export default function CancelVoucher({show, onHide, voucherId, onGroups, skip, take, orderBy, sortField, filterInputs}) {
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await cancelVoucher(voucherId);
      await onGroups({skip, take, orderBy, sortField, ...filterInputs});
      onHide(false);
    } catch (err) {
      setError(err?.response?.message ?? "An error occurred")
    }
  }

  return (
    <Modal
      size="md"
      aria-labelledby="create"
      centered
      className="modal pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Ləvğ etməyə əminsiniz?</Modal.Title>
        </Modal.Header>
        <RenderIf condition={Boolean(error)}>
          <Modal.Body>
            <p className="text-danger">{error}</p>
          </Modal.Body>
        </RenderIf>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Xeyr
          </Button>
          <Button type="submit">Bəli</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
