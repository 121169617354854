import React from "react";
import {Button} from "react-bootstrap";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        borderRadius: "50px"
    }
})

const ButtonComponent = ({children, className = "", ...props}) => {
    const classes = useStyles();

    return (
        <Button className={`${classes.root} ${className}`} {...props}>{children}</Button>
    )
}

export default ButtonComponent;