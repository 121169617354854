import React, { useState, useEffect } from "react";
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles/index";
import { Modal, Button } from "react-bootstrap";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from "@material-ui/core/TablePagination";
import Paper from '@material-ui/core/Paper';
import { objectToQueryString } from "_metronic/_helpers";
import { format } from "date-fns";

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const headRows = [
    {
        id: "increaseAmount",
        numeric: false,
        disablePadding: true,
        label: "Increase amount",
    },
    {
        id: "laterAmount",
        numeric: false,
        disablePadding: true,
        label: "Later amount",
    },
    {
        id: "operationTime",
        numeric: false,
        disablePadding: true,
        label: "Operation time",
    },
    {
        id: "operatedByName",
        numeric: false,
        disablePadding: true,
        label: "Operated by name",
    },
];



export default function AgentTraces({ show, onHide, agentId }) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0); 
  const [filter, setFilter] = useState({
    skip: 0,
    take: 10,
    agentId,
    sortField: 'operationTime'
  });

  const handleChangePage = (e, page) => {
    setPage(page);
    setFilter(prev => ({
        ...prev, skip: page * prev.take
    }))
  }

  const handleChangeRowsPerPage = (e) => {
    setFilter(prev => ({
        ...prev, take: e.target.value
    }))
  }

  useEffect(() => {
    axios
    .get(`agents/agentTraces?${objectToQueryString(filter)}`)
    .then(({data}) => {
        setData(data?.data ?? []);
        setTotalCount(data?.totalCount ?? 0);
    })
    .catch(err => {
        console.log(err)
    })
  }, [filter])

  return (
    <Modal
      size="lg"
      onHide={onHide}
      show={show}
      aria-labelledby="traces"
      className="modal pr-0"
    >
        <Modal.Header closeButton>
          <Modal.Title id="edit">Balans tarixçəsi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headRows.map(row => (
                <TableCell key={row.id}>{row.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.increaseAmount} AZN</TableCell>
              <TableCell align="left">{row.laterAmount} AZN</TableCell>
              <TableCell align="left">{row.operationTime ? format(new Date(row.operationTime), 'dd.MM.yyyy HH:mm:ss') : ""}</TableCell>
              <TableCell align="left">{row.operatedByName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        component="div"
        count={totalCount}
        page={page}
        rowsPerPage={filter.take}
        rowsPerPageOptions={[1, 10, 15, 20, 50, 100]}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage} 
    />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Bağla
          </Button>
        </Modal.Footer>
    </Modal>
  );
}
