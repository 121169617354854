import React, { useState } from "react";
import {
  createMuiTheme,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  ThemeProvider,
} from "@material-ui/core";
import { BiSort, BiSortUp, BiSortDown } from "react-icons/bi";
import { azAZ } from "@material-ui/core/locale";

const theme = createMuiTheme(azAZ);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "10px",
    borderRadius: "18px",
    background: "#F5F5F5",
    padding: "0 8px 8px 8px",
  },
  tableRow: {
    borderRadius: "18px",
    border: "unset",
    padding: "16px",
    "&:hover": {
      backgroundColor: "unset !important",
    },
  },
  table: {
    minWidth: 750,
    borderCollapse: "separate",
    borderSpacing: "0 4px",
  },
  tableHeadCell: {
    border: "unset",
    padding: "4px",
    "&:first-child": {
      paddingLeft: "10px",
    },
    "&:last-child": {
      paddingRight: "10px",
    },
  },
  tableCell: {
    border: "unset",
    background: "#FFFFFF",
    marginBottom: "16px",
    padding: "14px",
    "&:first-child": {
      borderRadius: "18px 0 0 18px",
      paddingLeft: "10px",
    },
    "&:last-child": {
      borderRadius: "0 18px 18px 0",
      paddingRight: "10px",
    },
  },
  tableWrapper: {
    overflowX: "auto",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));

export const Icon = ({ sortField, orderBy, fieldName }) => {
  if (sortField === fieldName && orderBy) {
    return <BiSortUp />;
  } else if (sortField === fieldName && !orderBy) {
    return <BiSortDown />;
  } else {
    return <BiSort />;
  }
};

const tableHeadList = [
  {
    id: "agentCreatedBet",
    label: "Agent created bet",
  },
  {
    id: "mobileNumber",
    label: "Mobile number",
  },
  {
    id: "voucherNumber",
    label: "Voucher number",
  },
  {
    id: "ticketId",
    label: "Ticket ID",
  },
  {
    id: "matchId",
    label: "Match ID",
  },
  {
    id: "moveId",
    label: "Move ID",
  },
  {
    id: "roundId",
    label: "Round ID",
  },
  {
    id: "ticketStatus",
    label: "Ticket status",
  },
  {
    id: "placementDateTime",
    label: "Placement date time",
  },
  {
    id: "settlementDateTime",
    label: "Settlement Date & Time",
  },
  {
    id: "stakeAmount",
    label: "Stake amount",
  },
  {
    id: "grossWinningAmount",
    label: "Gross winning amount",
  },
  {
    id: "netWinningAmount",
    label: "Net winning amount",
  },
  {
    id: "winningTaxAmount",
    label: "Winning tax amount",
  },
  {
    id: "balanceAfterBet",
    label: "Balance after bet",
  },
  {
    id: "balanceAfterWin",
    label: "Balance after win",
  },
];

const format = (value) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const formatter = new Intl.DateTimeFormat("az-Az", options);
  if (value) {
    return formatter.format(new Date(value));
  }
  return null;
};

export default function ReportsTable({
  data,
  orderBy,
  setOrderBy,
  sortField,
  setSortField,
  take,
  setSkip,
  setTake,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const classes = useStyles();

  function handleSort(fieldName) {
    const order = fieldName === sortField && !orderBy;
    setSortField(fieldName);
    setOrderBy(order);
  }

  function handleChangePage(e, page) {
    setPage(page);
    const tempSkip = page > 0 ? page * take : 0;
    setSkip(tempSkip);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setTake(+event.target.value);
    setPage(0);
    setSkip(0);
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeadList.map(({ id, label }) => (
                <TableCell
                  key={id}
                  className={classes.tableHeadCell}
                  sortDirection={sortField === id ? "asc" : false}
                >
                  <TableSortLabel
                    active={sortField === id}
                    direction={orderBy ? "desc" : "asc"}
                    onClick={() => handleSort(id)}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>Total</TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>
                {data?.totalStakeAmount}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.totalGrossWinning}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.totalNetWinning}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.totalWinningTax}
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
            </TableRow>
            {data?.data?.map((ticket, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  {ticket?.agentCreatedBet}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.mobileNumber}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.voucherNumber}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.ticketId}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.matchId}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.moveId}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.roundId}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.ticketStatus}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {format(ticket?.placementDateTime)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {format(ticket?.settlementDateTime)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.stakeAmount}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.grossWinningAmount}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.netWinningAmount}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.winningTaxAmount}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.balanceAfterBet}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.balanceAfterWin}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data.count > 10 && (
          <ThemeProvider theme={theme}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.count}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        )}
      </div>
    </div>
  );
}
