import React, {useEffect, useState} from "react";
import DataTables from "./components/OrdersTable";
import axios from "axios";
import {updateObject} from "../../../helpers/updateObject";
import {objectToQueryString} from "../../../_metronic/_helpers";
import RenderIf from "../../components/RenderIf";
import {makeStyles} from "@material-ui/core";
import {PaymentIcon, SalesIcon} from "../../components/Icons";
import {Input, Select} from "../../components";
import dayjs from "dayjs";
import {DateTimePicker} from "../../components/DatePicker";
import { Button } from "react-bootstrap";

const useStyles = makeStyles({
    redButton: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #E2241B",
        marginInline: "4px",
        padding: "6px 12px",
        color: "#E2241B",
        fontWeight: 500,
        fontSize: "14px",
        '& svg': {
            fill: "#E2241B",
            marginRight: "6px"
        }
    },
    greenButton: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #40C4BF",
        marginInline: "4px",
        padding: "6px 12px",
        color: "#40C4BF",
        fontWeight: 500,
        fontSize: "14px",
        '& svg': {
            fill: "#40C4BF",
            marginRight: "6px"
        }
    }
})

const statusOptions = [
    {
        label: "Hamısı",
        value: ""
    },
    {
        label: "In progress",
        value: 1
    },
    {
        label: "Pending",
        value: 2
    },
    {
        label: "Confirmed",
        value: 3
    },
    {
        label: "Declined",
        value: 4
    },
    {
        label: "Refunded",
        value: 5
    },
    {
        label: "Authorized",
        value: 6
    },
    {
        label: "Cancelled",
        value: 7
    },
    {
        label: "Timeout",
        value: 8
    },
];

const typeOptions = [
    {
        label: "Hamısı",
        value: ""
    },
    {
        label: "Deposit",
        value: 0
    },
    {
        label: "Withdraw",
        value: 1
    },
];

function Orders() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("createdAt");
    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showUpdateVoucher, setShowUpdateVoucher] = useState(false);
    const [showCancelVoucher, setShowCancelVoucher] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showUpdateSales, setShowUpdateSales] = useState(false);
    const [showUpdatePayment, setShowUpdatePayment] = useState(false);
    const [showReprint, setShowReprint] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [info, setInfo] = useState({});
    
    const [filterInputs, setFilterInputs] = useState({
        agentNumber: "",
        phoneNumber: "",
        paymentStatus: "",
        paymentType: "",
        startDate: "",
        endDate: ""
    });

    const isFrozenBySystem = groups[0]?.isFrozenBySystem;
    const isPaymentBlocked = groups[0]?.isPaymentBlocked;

    const handleReset = () => {
        setFilterInputs({
            agentNumber: "",
        phoneNumber: "",
        paymentStatus: "",
        paymentType: "",
        startDate: "",
        endDate: ""
        })
    }

    function handleChange() {
        setShowFilter((key) => !key);
    }

    const onGroups = (filter = {}) => {
        setIsLoading(true);

        const {isActive, ...rest} = filter;

        const query = objectToQueryString({...rest, isActive: isActive === 'all' ? "" : isActive});

        axios
            .get(`payment/orders?${query}`)
            .then(({data: {data, totalCount}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const downloadExcel = async () => {
        const query = objectToQueryString(filterInputs);

        try {
            const {data} = await axios({
                url: `payment/orders?${query}`,
                method: "GET",
                responseType: "blob",
                headers: {
                    "X-Content-Type": "application/xlsx"
                    }
            });
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Vouchers.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
        }
    }

    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [filterInputs, skip, take, orderBy, sortField]);

    const classes = useStyles();

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center pb-4">
                <h2 className="mb-0 font-weight-bolder">Transactions</h2>
                <div className="d-flex pl-15">
                    <span className="mr-4 text-nowrap">Count: {totalCount}</span>
                </div>
                <Button onClick={downloadExcel} variant="outline-success" className="ml-auto">Download excel</Button>
                <RenderIf
                    condition={typeof isFrozenBySystem !== 'undefined' || typeof isPaymentBlocked !== 'undefined'}>
                    <div className="w-100 d-flex justify-content-end">
                        <RenderIf condition={typeof isFrozenBySystem !== 'undefined'}>
                            <button
                                style={{padding: "6px 12px", fontSize: "11px", whiteSpace: "nowrap"}}
                                onClick={() => setShowUpdateSales(true)}
                                className={isFrozenBySystem ? classes.greenButton : classes.redButton}
                            >
                                <SalesIcon/>{isFrozenBySystem ? "Satışı aktivləşdir" : "Satışı dayandır"}
                            </button>
                        </RenderIf>
                        <RenderIf condition={typeof isPaymentBlocked !== 'undefined'}>
                            <button
                                style={{padding: "6px 12px", fontSize: "11px", whiteSpace: "nowrap"}}
                                onClick={() => setShowUpdatePayment(true)}
                                className={isPaymentBlocked ? classes.greenButton : classes.redButton}
                            >
                                <PaymentIcon/>
                                {isPaymentBlocked ? "Uduş ödənişini aktivləşdir" : "Uduş ödənişini dayandır"}
                            </button>
                        </RenderIf>
                    </div>
                </RenderIf>
            </div>
            <div className="d-flex w-100">
                <Input
                    name="agentNumber"
                    value={filterInputs.agentNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Agent"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="phoneNumber"
                    value={filterInputs.phoneNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Mobile"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <DateTimePicker
                    placeholder="Başlanğıc tarixi"
                    className="mr-2"
                    value={filterInputs.startDate ? dayjs(filterInputs.startDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, startDate: value
                    }))}
                />
                <DateTimePicker
                    placeholder="Bitmə tarixi"
                    className="mr-2"
                    value={filterInputs.endDate ? dayjs(filterInputs.endDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, endDate: value
                    }))}
                />
                <Select
                    placeholder="Payment status"
                    options={statusOptions} onChange={(e) => setFilterInputs(prevState => ({
                    ...prevState, paymentStatus: e.value
                }))}
                    className="mr-2"
                />
                 <Select
                    placeholder="Payment type"
                    options={typeOptions} onChange={(e) => setFilterInputs(prevState => ({
                    ...prevState, paymentType: e.value
                }))}
                    className="mr-2"
                />
            </div>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowUpdateVoucher={setShowUpdateVoucher}
                setShowCancelVoucher={setShowCancelVoucher}
                setShowReprint={setShowReprint}
                setShowInfo={setShowInfo}
                setInfo={setInfo}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                onGroups={onGroups}
                skip={skip}
                setSkip={setSkip}
                setTake={setTake}
                setOrderBy={setOrderBy}
                setSortField={setSortField}
                take={take}
                orderBy={orderBy}
                sortField={sortField}
                totalCount={totalCount}
                setShowChangePassword={setShowChangePassword}
                filterInputs={filterInputs}
            />
        </div>
    );
}

export default Orders;
