import React, {useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
// eslint-disable-next-line no-restricted-imports
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import {lighten, makeStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HistoryIcon from '@material-ui/icons/History';
import {azAZ} from '@material-ui/core/locale';
import {Badge} from "react-bootstrap";
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Paper,
    Tooltip,
    CircularProgress, TableSortLabel,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Toggle from "./Toggle";
import {updateTestAgent} from "../requests";

const theme = createMuiTheme(azAZ);

const headRows = [
    {
        id: "number",
        numeric: false,
        disablePadding: true,
        label: "Agent nömrəsi",
    },
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Ad",
    },
    {
        id: "deposit",
        numeric: false,
        disablePadding: true,
        label: "Balans",
    },
    {
        id: "user.phoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Telefon nömrəsi",
    },
    {
        id: "taxationNumber",
        numeric: false,
        disablePadding: true,
        label: "Taxation number",
    },
    {
        id: "created_By.Name",
        numeric: false,
        disablePadding: true,
        label: "Yaratmışdır",
    },
    {
        id: "updated_By.Name",
        numeric: false,
        disablePadding: true,
        label: "Yeniləmişdir",
    },
    {
        id: "region.name",
        numeric: false,
        disablePadding: true,
        label: "Region adı",
    },
    {
        id: "isActive",
        numeric: false,
        disablePadding: true,
        label: "Aktivlik statusu",
    },
    {
        id: "isFrozen",
        numeric: false,
        disablePadding: true,
        label: "Frozen/Non-Frozen",
    },
    {
        id: "operations",
        numeric: false,
        disablePadding: true,
        label: "Əməliyyatlar",
    },
];

function EnhancedTableHead(props) {
    const {
        orderBy,
        sortField,
        onRequestSort,
    } = props;

    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {headRows.map((row, index) => {
                    if (row.id === "operations") {
                        return (
                            <TableCell key={index}
                                       align={row.numeric ? "right" : "left"} className={classes.tableHeadCell}>
                                <div>
                                    {row.label}
                                </div>
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell sortDirection={sortField === row.id ? "asc" : false} key={index}
                                       align={row.numeric ? "right" : "left"} className={classes.tableHeadCell}>
                                <TableSortLabel active={sortField === row.id} direction={orderBy ? "desc" : "asc"}
                                                onClick={() => {
                                                    onRequestSort(row.id)
                                                }}>
                                    {row.label}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        justifyContent: "flex-end",
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
    dense: {
        margin: theme.spacing(2),
    },
}));


const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        marginTop: "10px",
        borderRadius: "18px",
        background: "#F5F5F5",
        padding: "0 8px 8px 8px"
    },
    tableRow: {
        borderRadius: "18px",
        border: "unset",
        padding: "16px",
        "&:hover": {
            backgroundColor: "unset !important"
        }
    },
    table: {
        minWidth: 750,
        borderCollapse: "separate",
        borderSpacing: "0 4px"
    },
    tableHeadCell: {
        border: "unset",
        padding: "3px",
        '&:first-child': {
            paddingLeft: "10px"
        },
        '&:last-child': {
            paddingRight: "10px"
        },
    },
    tableCell: {
        border: "unset",
        background: "#FFFFFF",
        marginBottom: "16px",
        padding: "3px",
        '&:first-child': {
            borderRadius: "18px 0 0 18px",
            paddingLeft: "10px"
        },
        '&:last-child': {
            borderRadius: "0 18px 18px 0",
            paddingRight: "10px"
        },
    },
    tableWrapper: {
        overflowX: "auto",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
}));

export default function EnhancedTable({
                                          groups,
                                          onGroups,
                                          isLoading,
                                          isError,
                                          setText,
                                          setChooseGroupId,
                                          setShowCreate,
                                          setShowRemove,
                                          setShowRemoveGroups,
                                          setShowEdit,
                                          setShowEditBalance,
                                          deleteGroupsIds,
                                          setDeleteGroupsIds,
                                          skip,
                                          take,
                                          orderBy,
                                          setSortField,
                                          setOrderBy,
                                          setSkip,
                                          setTake,
                                          sortField,
                                          totalCount,
                                          setShowChangePassword,
                                          filterInputs,
                                          setShowAgentTraces
                                      }) {
    const classes = useStyles();

    const {
        Agent_Update,
        Agent_Delete,
        Agent_ChangeActiveStatus,
        Agent_View,
        Agent_ChangePassword
    } = useSelector(state => state.auth.user.permissions, shallowEqual)

    const [isUpdating, setIsUpdating] = useState(false);

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = groups.map((n) => n.id);
            setDeleteGroupsIds(newSelecteds);
            return;
        }
        setDeleteGroupsIds([]);
    }

    const isSelected = (name) => deleteGroupsIds.indexOf(name) !== -1;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (field) => {

        const isOrder = (field === sortField && !orderBy)

        setSortField(field);
        setOrderBy(isOrder)

    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
        setSkip(take * newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setTake(+event.target.value);
        setPage(0);
        setSkip(0);
    }

    const changeActiveStatus = (id) => {
        axios.put(`agents/changeActiveStatus`, {id})
            .then(() => {
                onGroups({skip, take, orderBy, sortField, ...filterInputs});
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleChangeTestAgent = async (query) => {
        try {
            setIsUpdating(true);
            await updateTestAgent(query);
            onGroups({skip, take, orderBy, sortField, ...filterInputs})
        } catch (err) {

        } finally {
            setIsUpdating(false);
        }
    }

    const changeFrozenStatus = async (id) => {
        axios({
            method: "PUT",
            url: "agent/changeFrozenStatus",
            params: {id}
        })
            .then(() => {
                onGroups({skip, take, orderBy, sortField, ...filterInputs})
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className={classes.root}>
                {isLoading && (
                    <div className={classes.progresRoot}>
                        <CircularProgress className={classes.progress}/>
                    </div>
                )}
                <div className={classes.tableWrapper}>
                    {groups.length ? (
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={deleteGroupsIds.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={groups.length}
                                onRequestSort={handleRequestSort}
                                sortField={sortField}
                                orderBy={orderBy}
                            />
                            <TableBody>
                                {groups.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            className={classes.tableRow}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.number}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.deposit} AZN
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.phoneNumber}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.taxationNumber}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.createdByName ? row.createdByName : "-"}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.updatedByName ? row.updatedByName : "-"}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.regionName}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.isActive
                                                    ? <Badge variant="success">Aktiv</Badge>
                                                    : <Badge variant="danger">Deaktiv</Badge>}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCell}>
                                                {row.isFrozen
                                                    ? <Badge variant="danger">Frozen</Badge>
                                                    : <Badge variant="success">Non-Frozen</Badge>}
                                            </TableCell>

                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                className={classes.tableCell}
                                            >
                                                {Agent_ChangeActiveStatus && (
                                                    <Tooltip title={row.isActive ? "Deaktiv et" : "Aktivləşdir"}
                                                             placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className={`btn btn-sm btn-icon btn-bg-light btn-hover-secondary mr-2 btn-text-success`}
                                                            onClick={() => {
                                                                changeActiveStatus(row.id)
                                                            }}
                                                        >
                                                            {row.isActive ?
                                                                <span
                                                                    className="active-user"><PowerSettingsNewIcon/></span> :
                                                                <span
                                                                    className="deactive-user"><PowerSettingsNewIcon/></span>}
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {Agent_ChangeActiveStatus && (
                                                    <Toggle
                                                        onChangeFrozenStatus={() => changeFrozenStatus(row?.id)}
                                                        status={row?.isFrozen}
                                                    />
                                                )}
                                                {Agent_ChangePassword && (
                                                    <Tooltip title="Şifrəni dəyiş" placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowChangePassword(true);
                                                            }}
                                                        >
                                                            <VpnKeyIcon/>
                                                        </button>
                                                    </Tooltip>
                                                )}
                                                 {Agent_Update && (
                                                    <Tooltip title="Balans tarixçəsi" placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info mr-2"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowAgentTraces(true);
                                                            }}
                                                        >
                                                            <HistoryIcon/>
                                                        </button>
                                                    </Tooltip>
                                                )}
                                                {Agent_Update && (
                                                    <Tooltip title="Balans artırmaq" placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-success btn-hover-success mr-2"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowEditBalance(true);
                                                            }}
                                                        >
                                                            <MonetizationOnIcon/>
                                                        </button>
                                                    </Tooltip>
                                                )}
                                                {Agent_Update && (
                                                    <Tooltip title="Düzəliş et" placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning mr-2"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowEdit(true);
                                                            }}
                                                        >
                                                            <EditIcon/>
                                                        </button>
                                                    </Tooltip>
                                                )}
                                                {Agent_Update && (
                                                    <Tooltip title={row.isTest ? "Test agentini ləğv et" : "Test agenti et"} placement="top">
                                                        <button
                                                            aria-label="edit"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                                                            onClick={() => {
                                                                handleChangeTestAgent(`agentId=${row.id}&isTest=${!row.isTest}`)
                                                            }}
                                                            disabled={isUpdating}
                                                        >
                                                            {row.isTest ?
                                                                <span
                                                                    className="active-user"><TransferWithinAStationIcon/></span> :
                                                                <span
                                                                    className="deactive-user"><TransferWithinAStationIcon/></span>}
                                                        </button>
                                                    </Tooltip>
                                                )}

                                                {Agent_Delete && (
                                                    <Tooltip title="Sil" placement="top">
                                                        <button
                                                            aria-label="Delete"
                                                            className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                                                            onClick={() => {
                                                                setChooseGroupId(row.id);
                                                                setShowRemove(true);
                                                            }}
                                                        >
                                                            <DeleteIcon/>
                                                        </button>
                                                    </Tooltip>
                                                )}
                                                {!Agent_Update && !Agent_ChangeActiveStatus && !Agent_Delete ?
                                                    <div className="p-5"><span>-</span></div> : ""}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    ) : !groups.length && !isLoading && !isError ? (
                        <AlertComponent variant="info" message="Məlumat tapılmadı."/>
                    ) : (!isLoading && isError && (!isLoading && isError && (<AlertComponent
                        variant="danger"
                        message="Şəbəkəylə bağlantı kəsildi."
                    />)))}
                </div>
                {totalCount > 10 && (
                    <ThemeProvider theme={theme}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "Previous Page",
                            }}
                            nextIconButtonProps={{
                                "aria-label": "Next Page",
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </ThemeProvider>
                )}
        </div>
    );
}
