import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import {makeStyles} from "@material-ui/core/styles/index";
import {Modal, Button} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {useFormik} from "formik";
import * as Yup from "yup";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {actions} from "app/components/Snackbar/snackbarReducer";

const useStyles = makeStyles({
    list: {
        width: 400,
        height: "100vh",
    },
    card: {
        margin: "30px",
    },
    pointer: {
        cursor: "pointer",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
    input: {
        outline: 'none',
        textAlign: 'center',
        appearance: 'textfield'
    },
    container: {
        width: '100%',
        height: '45px',
        display: 'flex',
        justifyContent: 'center',
    },
    decrementButton: {
        background: '#eeeeee',
        borderRadius: '50% 0 0 50%',
        border: 'unset',
        width: '50px',
        color: '#000000'
    },
    incrementButton: {
        background: '#000000',
        color: '#FFFFFF',
        borderRadius: '0 50% 50% 0',
        border: 'unset',
        width: '50px'
    },
});

export default function EditBalance({show, onHide, agentId, onGroups, skip, take, sortField, orderBy, filterInputs}) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const formik = useFormik({
        initialValues: {
            agentId,
            amount: 0
        },
        validationSchema: Yup.object({
            amount: Yup.number().required()
        }),
        onSubmit: async (values) => {
            try {
                await axios.post('agents/increaseDeposit', values);
                onGroups({skip, take, orderBy, sortField, ...filterInputs});
                dispatch(actions.setSnackbar({type: 'success', message: "Agent balansı uğurla yeniləndi."}))
                onHide(false);
            } catch(err) {
                dispatch(actions.setSnackbar({type: 'error', message: err.response?.data?.message ?? "Xəta baş verdi."}))
            }
        }
    })

    const onClickButton = (e) => {
        if(e.target.name === 'decrement') {
            formik.setFieldValue('amount', formik.values.amount > 100 ? formik.values.amount - 100 : 0)
        }
        if(e.target.name === 'increment') {
            formik.setFieldValue('amount', formik.values.amount + 100)
        }
    }

    return (
        <Modal
            size="md"
            onHide={onHide}
            show={show}
            aria-labelledby="edit"
            className="modal pr-0"
        >
             <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title id="edit">Balans artırmaq</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={classes.container}>
                            <button name="decrement" type="button" onClick={onClickButton} className={classes.decrementButton}>-</button>
                            <input type="number" {...formik.getFieldProps('amount')} className={classes.input} />
                            <button name="increment" type="button" onClick={onClickButton} className={classes.incrementButton}>+</button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" className="w-100 border-0 py-4" style={{backgroundColor: "#00AFF5"}}>Təsdiqləmək</Button>
                    </Modal.Footer>
                </form>
        </Modal>
    );
}
