import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
// eslint-disable-next-line no-restricted-imports
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import { makeStyles } from "@material-ui/core";
// eslint-disable-next-line no-restricted-imports
import { azAZ } from "@material-ui/core/locale";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  TableSortLabel,
  createMuiTheme,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import { TablePagination } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import Info from "@material-ui/icons/Info";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import PrintIcon from '@material-ui/icons/Print';
import { format } from "date-fns";

const theme = createMuiTheme(azAZ);

const headRows = [
  {
    id: "createdDateTime",
    numeric: false,
    disablePadding: true,
    label: "Yaradılma tarixi",
  },
  {
    id: "agentNumber",
    numeric: false,
    disablePadding: true,
    label: "Agent nömrəsi",
  },
  {
    id: "voucherNumber",
    numeric: false,
    disablePadding: true,
    label: "Vouçer nömrəsi",
  },
  {
    id: "expirationDate",
    numeric: false,
    disablePadding: true,
    label: "Bitmə tarixi",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: true,
    label: "Məbləğ",
  },
  {
    id: "voucherTypeString",
    numeric: false,
    disablePadding: true,
    label: "Voucher type",
  },
  {
    id: "customerNumber",
    numeric: false,
    disablePadding: true,
    label: "Müştəri",
  },
  {
    id: "statusString",
    numeric: false,
    disablePadding: true,
    label: "Status",
  }, 
  {
    id: "useDate",
    numeric: false,
    disablePadding: true,
    label: "İstifadə edilmə tarixi",
  },
  {
    id: "operations",
    numeric: false,
    disablePadding: true,
    label: "Əməliyyatlar",
  },
];

const statusBadges = {
  Pending: "badge-warning",
  Read: "badge-success",
  Cancelled: "badge-primary",
};

const statusStrings = {
  Pending: "Gözləmədə",
  Read: "Oxudulub",
  Cancelled: "Ləğv edilib",
};

const voucherTypeBadges = {
  "Online": "badge-info",
  "Retail": "badge-success"
}

function EnhancedTableHead(props) {
  const { orderBy, sortField, onRequestSort } = props;

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row, index) => {
          if (row.id === "operations") {
            return (
              <TableCell
                key={index}
                align={row.numeric ? "right" : "left"}
                className={classes.tableHeadCell}
              >
                <div>{row.label}</div>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                sortDirection={sortField === row.id ? "asc" : false}
                key={index}
                align={row.numeric ? "right" : "left"}
                className={classes.tableHeadCell}
              >
                <TableSortLabel
                  active={sortField === row.id}
                  direction={orderBy ? "desc" : "asc"}
                  onClick={() => {
                    onRequestSort(row.id);
                  }}
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "10px",
    borderRadius: "18px",
    background: "#F5F5F5",
    padding: "0 8px 8px 8px",
  },
  tableRow: {
    borderRadius: "18px",
    border: "unset",
    padding: "16px",
    "&:hover": {
      backgroundColor: "unset !important",
    },
  },
  table: {
    minWidth: 750,
    borderCollapse: "separate",
    borderSpacing: "0 4px",
  },
  tableHeadCell: {
    border: "unset",
    padding: "3px",
    "&:first-child": {
      paddingLeft: "10px",
    },
    "&:last-child": {
      paddingRight: "10px",
    },
  },
  tableCell: {
    border: "unset",
    background: "#FFFFFF",
    marginBottom: "16px",
    // padding: "3px",
    padding: "4px",
    "&:first-child": {
      borderRadius: "18px 0 0 18px",
      paddingLeft: "10px",
    },
    "&:last-child": {
      borderRadius: "0 18px 18px 0",
      paddingRight: "10px",
    },
  },
  tableWrapper: {
    overflowX: "auto",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));

export default function EnhancedTable({
  groups,
  isLoading,
  isError,
  deleteGroupsIds,
  setDeleteGroupsIds,
  take,
  orderBy,
  setSortField,
  setOrderBy,
  setSkip,
  setTake,
  sortField,
  totalCount,
  setShowUpdateVoucher,
  setShowCancelVoucher,
  setShowInfo,
  setInfo,
  setChooseGroupId,
  setShowReprint
}) {
  const classes = useStyles();

  const { Voucher_Update } = useSelector(
    (state) => state.auth.user.permissions,
    shallowEqual
  );

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = groups.map((n) => n.id);
      setDeleteGroupsIds(newSelecteds);
      return;
    }
    setDeleteGroupsIds([]);
  }

  const isSelected = (name) => deleteGroupsIds.indexOf(name) !== -1;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (field) => {
    const isOrder = field === sortField && !orderBy;

    setSortField(field);
    setOrderBy(isOrder);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    setSkip(take * newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setTake(+event.target.value);
    setPage(0);
    setSkip(0);
  }

  return (
    <div className={classes.root}>
      {isLoading && (
        <div className={classes.progresRoot}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
      <div className={classes.tableWrapper}>
        {groups.length ? (
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={deleteGroupsIds.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={groups.length}
              onRequestSort={handleRequestSort}
              sortField={sortField}
              orderBy={orderBy}
            />
            <TableBody>
              {groups.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    className={classes.tableRow}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.createdDateTime
                        ? format(
                            new Date(row.createdDateTime),
                            "dd.MM.yyyy HH:mm:ss"
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.agentNumber}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.voucherNumber}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.expirationDate
                        ? format(
                            new Date(row.expirationDate),
                            "dd.MM.yyyy HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.price} AZN
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      <span
                        className={`badge ${voucherTypeBadges[row.voucherTypeString]}`}
                      >
                        {row.voucherTypeString}
                      </span>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.customerNumber ?? "-"}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      <span
                        className={`badge ${statusBadges[row.statusString]}`}
                      >
                        {statusStrings[row.statusString]}
                      </span>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.useDate
                        ? format(new Date(row.useDate), "dd.MM.yyyy HH:mm:ss")
                        : "-"}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.tableCell}
                    >
                      <Tooltip title="Bet history" placement="top">
                        <button
                          aria-label="view"
                          className="btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info mr-2"
                          onClick={() => {
                            window.open(
                              `#/bet-history?voucherNumber=${row.voucherNumber}`,
                              "_blank"
                            );
                          }}
                        >
                          <EyeIcon />
                        </button>
                      </Tooltip>
                      <Tooltip title="Info" placement="top">
                        <button
                          aria-label="edit"
                          className={`btn btn-sm btn-icon btn-bg-light btn-hover-success mr-2 btn-text-success`}
                          onClick={() => {
                            setInfo(row);
                            setShowInfo(true);
                          }}
                        >
                          <Info />
                        </button>
                      </Tooltip>
                      {Voucher_Update && row.statusString === "Pending" && (
                        <Tooltip title="Voucher update" placement="top">
                          <button
                            aria-label="edit"
                            className={`btn btn-sm btn-icon btn-bg-light btn-hover-warning mr-2 btn-text-warning`}
                            onClick={() => {
                              setChooseGroupId(row.id);
                              setShowUpdateVoucher(true);
                            }}
                          >
                            <UpdateIcon />
                          </button>
                        </Tooltip>
                      )}
                      {Voucher_Update && row.statusString === "Pending" && (
                        <Tooltip title="Voucher reprint" placement="top">
                          <button
                            aria-label="reprint"
                            className={`btn btn-sm btn-icon btn-bg-light btn-hover-warning mr-2 btn-text-warning`}
                            onClick={() => {
                              setChooseGroupId(row.id);
                              setShowReprint(true);
                            }}
                          >
                            <PrintIcon />
                          </button>
                        </Tooltip>
                      )}
                      {Voucher_Update && row.statusString === "Pending" && (
                        <Tooltip title="Voucher cancel" placement="top">
                          <button
                            aria-label="edit"
                            className={`btn btn-sm btn-icon btn-bg-light btn-hover-danger mr-2 btn-text-danger`}
                            onClick={() => {
                              setChooseGroupId(row.id);
                              setShowCancelVoucher(true);
                            }}
                          >
                            <CancelIcon />
                          </button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : !groups.length && !isLoading && !isError ? (
          <AlertComponent variant="info" message="Məlumat tapılmadı." />
        ) : (
          !isLoading &&
          isError &&
          !isLoading &&
          isError && (
            <AlertComponent
              variant="danger"
              message="Şəbəkəylə bağlantı kəsildi."
            />
          )
        )}
      </div>
      {totalCount > 10 && (
        <ThemeProvider theme={theme}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </ThemeProvider>
      )}
    </div>
  );
}
