import React, {useState, useEffect} from "react";
import axios from "axios";
import AlertComponent from "_metronic/_helpers/AlertComponent";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import Table from "./components/Table";
import {InputLabel, TextField, FormControlLabel, Switch} from "@material-ui/core";
import {Button} from "react-bootstrap";
import {objectToQueryString} from "../../../_metronic/_helpers";

const date = new Date();

const initialStartDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().split('T')[0];
const initialEndDate = date.toISOString().split('T')[0];

export default function TotalRemainingReport() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("");
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const [hideTestAgents, setHideTestAgents] = useState(true);
    const [state, setState] = useState({
        isLoading: false,
        error: null,
        data: null,
    });

    async function getData() {
        if(startDate && endDate) {
            setState({
                isLoading: true,
                error: null,
                data: null
            });
            try {
                const query = objectToQueryString({skip, take, sortField, orderBy, startDate, endDate, hideTestAgents})
                const {data} = await axios({
                    method: "GET",
                    url: `reports/dailyreport?${query}`,
                });
                setState({
                    isLoading: false,
                    error: null,
                    data
                })
            } catch (err) {
                setState({
                    isLoading: false,
                    error: err?.response?.data,
                    data: null
                })
            }
        }
    }

    async function getExcel() {
        try {
            const query = objectToQueryString({
                orderBy, sortField, startDate, endDate, hideTestAgents
            })
            const {data} = await axios({
                url: `reports/dailyreport/excel?${query}`,
                method: 'GET',
                responseType: 'blob'
            })
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = 'DailyReport.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {

        }
    }

    function handleChangeStartDate(e) {
        setStartDate(e.target.value)
    }

    function handleChangeEndDate(e) {
        setEndDate(e.target.value)
    }

    useEffect(() => {
        getData()
    }, [startDate, endDate, skip, take, sortField, orderBy, hideTestAgents])

    return (
        <div className="row bg-white rounded">
            <div className="col-12 py-4 overflow-auto">
                <div className="row pb-5">
                    <div className="col-3 col-md-3 col-lg-3">
                        <InputLabel>
                            Start date
                        </InputLabel>
                        <TextField
                            value={startDate}
                            onChange={handleChangeStartDate}
                            type="date"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <InputLabel>
                            End date
                        </InputLabel>
                        <TextField
                            value={endDate}
                            onChange={handleChangeEndDate}
                            type="date"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hideTestAgents}
                                    onChange={() => setHideTestAgents(prevState => !prevState)}
                                    color="secondary"
                                />
                            }
                            disabled={state.isLoading}
                            label="Test agentləri gizlə"
                            className="mb-0"
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3 d-flex align-items-end justify-content-between">
                        <RenderIf condition={state?.data?.dailyReports.length}>
                            <Button onClick={getExcel} variant="outline-success">Download excel</Button>
                        </RenderIf>
                    </div>
                </div>
                <RenderIf condition={state.data?.dailyReports?.length && !state?.isLoading}>
                    <Table
                        data={state.data?.dailyReports}
                        isLoading={state.isLoading}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        sortField={sortField}
                        setSortField={setSortField}
                        getExcel={getExcel}
                        take={take}
                        setTake={setTake}
                        setSkip={setSkip}
                        totalCount={state.data?.count}
                    />
                </RenderIf>
                <RenderIf condition={state.isLoading}>
                    <div className="d-flex justify-content-center">
                        <Loading/>
                    </div>
                </RenderIf>
                <RenderIf condition={!state.isLoading && state.data?.dailyReport?.length}>
                    <AlertComponent message="Məlumat tapılmadı" variant="info"/>
                </RenderIf>
                <RenderIf condition={!state.isLoading && state.error}>
                    <AlertComponent message="Xəta baş verdi" variant="danger"/>
                </RenderIf>
            </div>
        </div>
    )
}