import React from "react";
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles({
    root: {
        borderRadius: "16px",
        background: "#F3F6F9 !important",
        border: "1px solid"
    }
})

const Input = ({className = "", InputProps = {}, ...props}) => {
    const classes = useStyles();

    return (
        <TextField className={`${className} ${classes.root}`} InputProps={{...InputProps, disableUnderline: true}} {...props}/>
    )
}

export default Input;