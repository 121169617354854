import React from "react";
import Select from "react-select";

const styles = {
    container: (baseStyles) => ({
        ...baseStyles,
        minWidth: "200px",
        border: "unset !important",
    }),
    control: (baseStyles) => ({
        ...baseStyles,
        width: "100%",
        height: "100%",
        borderRadius: "16px",
        background: "#F3F6F9",
        border: "unset !important",
        boxShadow: "unset !important",
        borderColor: "unset",
        '&:hover': {
            boxShadow: "unset !important",
            border: "unset !important",
        },
        '&:active': {
            boxShadow: "unset !important",
            border: "unset !important",
        },
        '&:focus': {
            boxShadow: "unset !important",
            border: "unset !important",
        }
    }),
    indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: "none"
    })
}

const SelectComponent = (props) => {
    return (
        <Select styles={styles} {...props}/>
    )
}

export default SelectComponent;