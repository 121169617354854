import React, {useEffect, useState} from "react";
import DataTables from "./components/VouchersTable";
import {updateObject} from "../../../helpers/updateObject";
import {objectToQueryString} from "../../../_metronic/_helpers";
import {Input, Select} from "../../components";
import dayjs from "dayjs";
import {DateTimePicker} from "../../components/DatePicker";
import {getWinningVouchers} from "./requests";
import axios from "axios";
import { Button } from "react-bootstrap";

const statusOptions = [
    {
        label: "Hamısı",
        value: ""
    },
    {
        label: "OnHold",
        value: 1
    },
    {
        label: "Withdrawn",
        value: 2
    }
]

function WinningVouchers() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("createdDateTime");
    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalSum, setTotalSum] = useState(0);
    const [totalSumPaid, setTotalSumPaid] = useState(0);
    const [totalSumUnpaid, setTotalSumUnpaid] = useState(0);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [filterInputs, setFilterInputs] = useState({
        agentNumber: "",
        voucherNumber: "",
        customerNumber: "",
        createdDateTime: "",
        startDate: "",
        endDate: ""
    });

    const onGroups = (filter = {}) => {
        setIsLoading(true);

        const {isActive, ...rest} = filter;

        const query = objectToQueryString({...rest, isActive: isActive === 'all' ? "" : isActive});

        getWinningVouchers(query)
            .then(({data: {data, totalCount, totalSum, totalSumPaid, totalSumUnpaid}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setTotalSum(totalSum);
                setTotalSumPaid(totalSumPaid);
                setTotalSumUnpaid(totalSumUnpaid)
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const downloadExcel = async () => {
        const query = objectToQueryString({...filterInputs});

        try {
            const {data} = await axios({
                url: `winning/filter?${query}`,
                method: "GET",
                responseType: "blob",
                headers: {
                    "X-Content-Type": "application/xlsx"
                    }
            });
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "WinningVouchers.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
        }
    }


    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [filterInputs, skip, take, orderBy, sortField]);


    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center pb-4">
                <h2 className="mb-0 font-weight-bolder">Winning vouchers</h2>
                <div className="d-flex pl-15">
                    <span className="mr-4 text-nowrap">Count: {totalCount}</span>
                    <span className="mr-4 text-nowrap">Total amount: {totalSum} AZN</span>
                    <span className="mr-4 text-nowrap">Total amount paid: {totalSumPaid} AZN</span>
                    <span className="mr-4 text-nowrap">Total amount unpaid: {totalSumUnpaid} AZN</span>
                </div>
                <Button onClick={downloadExcel} variant="outline-success" className="ml-auto">Download excel</Button>
            </div>
            <div className="d-flex w-100">
                <Input
                    name="agentNumber"
                    value={filterInputs.agentNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Agent number"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="voucherNumber"
                    value={filterInputs.voucherNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Voucher number"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="customerNumber"
                    value={filterInputs.voucherNumbercustomerNumber}
                    onChange={filterInputsUpdate}
                    placeholder="Customer number"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <DateTimePicker
                    showTime
                    placeholder="Yaradılma tarixi"
                    className="mr-2"
                    value={filterInputs.createdDateTime ? dayjs(filterInputs.createdDateTime) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, createdDateTime: value.replace(" ", "T")
                    }))}
                />
                <DateTimePicker
                    placeholder="Başlanğıc tarixi"
                    className="mr-2"
                    value={filterInputs.startDate ? dayjs(filterInputs.startDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, startDate: value
                    }))}
                />
                <DateTimePicker
                    placeholder="Bitmə tarixi"
                    className="mr-2"
                    value={filterInputs.endDate ? dayjs(filterInputs.endDate) : ""}
                    onChange={value => setFilterInputs(prevState => ({
                        ...prevState, endDate: value
                    }))}
                />
                <Select
                    placeholder="Status"
                    options={statusOptions} onChange={(e) => setFilterInputs(prevState => ({
                    ...prevState, statusId: e.value
                }))}
                    className="mr-2"
                />

            </div>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                onGroups={onGroups}
                skip={skip}
                setSkip={setSkip}
                setTake={setTake}
                setOrderBy={setOrderBy}
                setSortField={setSortField}
                take={take}
                orderBy={orderBy}
                sortField={sortField}
                totalCount={totalCount}
                filterInputs={filterInputs}
            />
        </div>
    );
}

export default WinningVouchers;
