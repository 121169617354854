import React, {useState, useEffect} from "react";
import axios from "axios";
import AlertComponent from "_metronic/_helpers/AlertComponent";
import RenderIf from "app/components/RenderIf";
import Loading from "app/components/Loading";
import Table from "./components/Table";
import {InputLabel, TextField, FormControlLabel, Switch} from "@material-ui/core";
import {Button} from "react-bootstrap";
import {objectToQueryString} from "../../../_metronic/_helpers";

const initialFilter = {
    agentNumber: "",
    lessThan: "",
    greaterThan: ""
}

export default function DebitBalanceInfoReport() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("");
    const [filter, setFilter] = useState(initialFilter)
    const [hideTestAgents, setHideTestAgents] = useState(true);
    const [state, setState] = useState({
        isLoading: false,
        error: null,
        data: null,
        totalCount: 0
    });

    function handleChangeFilter(e) {
        e.persist();
        setFilter(prevState => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }

    function handleResetFilter() {
        setFilter(initialFilter);
        setSkip(0);
        setTake(10);
        setSortField("");
        setOrderBy(false);
    }

    async function getData() {
        setState(prevState => ({
            ...prevState, isLoading: true
        }))
        const query = objectToQueryString({skip, take, sortField, orderBy, ...filter, hideTestAgents});

        try {
            const {data} = await axios.get(`reports/debitBalanceInfo?${query}`);
            setState({
                isLoading: false,
                error: null,
                data: data.data ?? [],
                totalCount: data.totalCount ?? 0
            })
        } catch (err) {
            setState({
                isLoading: false,
                error: err?.response?.data,
                data: null,
                totalCount: 0
            })
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => getData(), 300);

        return () => clearTimeout(timeout);

    }, [skip, take, sortField, orderBy, filter, hideTestAgents])

    async function getExcel() {
        const query = objectToQueryString({skip, take, sortField, orderBy, ...filter, hideTestAgents});

        try {
            const {data} = await axios({
                url: `reports/debitBalanceInfo/excel?${query}`,
                method: 'GET',
                responseType: 'blob'
            })
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = 'DebitBalanceInfoReport.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {

        }
    }

    return (
        <div className="row bg-white rounded">
            <div className="col-12 py-4">
                <div className="row pb-5">
                    <div className="col-3 col-md-3 col-lg-3">
                        <InputLabel>
                            Agent nömrəsi
                        </InputLabel>
                        <TextField
                            name="agentNumber"
                            value={filter.agentNumber}
                            placeholder="Agent nömrəsi"
                            onChange={handleChangeFilter}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <InputLabel>
                            Less than
                        </InputLabel>
                        <TextField
                            name="lessThan"
                            value={filter.lessThan}
                            placeholder="Less than"
                            onChange={handleChangeFilter}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <InputLabel>
                            Greater than
                        </InputLabel>
                        <TextField
                            name="greaterThan"
                            value={filter.greaterThan}
                            placeholder="Greater than"
                            onChange={handleChangeFilter}
                        />
                    </div>
                    <div className="col-3 col-md-3 col-lg-3">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hideTestAgents}
                                    onChange={() => setHideTestAgents(prevState => !prevState)}
                                    color="secondary"
                                />
                            }
                            disabled={state.isLoading}
                            label="Test agentləri gizlə"
                            className="mb-0"
                        />
                    </div>
                    {/*<div className="col-3 col-md-3 col-lg-3 d-flex align-items-end justify-content-between">*/}
                    {/*    <Button onClick={handleResetFilter}>Reset</Button>*/}
                    {/*</div>*/}
                </div>
                <RenderIf condition={state.data?.length}>
                    <Table
                        data={state.data}
                        isLoading={state.isLoading}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        sortField={sortField}
                        setSortField={setSortField}
                        getExcel={getExcel}
                        take={take}
                        setTake={setTake}
                        setSkip={setSkip}
                        totalCount={state.totalCount}
                    />
                </RenderIf>
                <RenderIf condition={state.isLoading}>
                    <div className="d-flex justify-content-center">
                        <Loading/>
                    </div>
                </RenderIf>
                <RenderIf condition={!state.isLoading && !state.data?.length}>
                    <AlertComponent message="Məlumat tapılmadı" variant="info"/>
                </RenderIf>
                <RenderIf condition={!state.isLoading && state.error}>
                    <AlertComponent message="Xəta baş verdi" variant="danger"/>
                </RenderIf>
            </div>
        </div>
    )
}