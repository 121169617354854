import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import { BiSort, BiSortUp, BiSortDown } from "react-icons/bi";
import { azAZ } from "@material-ui/core/locale";

const theme = createMuiTheme(azAZ);

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: "10px",
    borderRadius: "18px",
    background: "#F5F5F5",
    padding: "0 8px 8px 8px",
  },
  tableRow: {
    borderRadius: "18px",
    border: "unset",
    padding: "16px",
    "&:hover": {
      backgroundColor: "unset !important",
    },
  },
  table: {
    minWidth: 750,
    borderCollapse: "separate",
    borderSpacing: "0 4px",
  },
  tableHeadCell: {
    border: "unset",
    padding: "4px",
    "&:first-child": {
      paddingLeft: "10px",
    },
    "&:last-child": {
      paddingRight: "10px",
    },
  },
  tableCell: {
    border: "unset",
    background: "#FFFFFF",
    marginBottom: "16px",
    padding: "14px",
    "&:first-child": {
      borderRadius: "18px 0 0 18px",
      paddingLeft: "10px",
    },
    "&:last-child": {
      borderRadius: "0 18px 18px 0",
      paddingRight: "10px",
    },
  },
  tableWrapper: {
    overflowX: "auto",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));

export const Icon = ({ sortField, orderBy, fieldName }) => {
  if (sortField === fieldName && orderBy) {
    return <BiSortUp />;
  } else if (sortField === fieldName && !orderBy) {
    return <BiSortDown />;
  } else {
    return <BiSort />;
  }
};

const tableHeadList = [
  {
    id: "agent_number",
    label: "Agent number",
  },
  {
    id: "agent_name",
    label: "Agent name",
  },
  {
    id: "taxation_number",
    label: "Taxation number",
  },
  {
    id: "voucher_sales",
    label: "Voucher sales",
  },
  {
    id: "voucher_cancellations",
    label: "Voucher cancellations",
  },
  {
    id: "voucher_converted_tickets",
    label: "Voucher converted tickets",
  },
  {
    id: "current_unused_vouchers",
    label: "Current unused vouchers",
  },
  {
    id: "read_vouchers",
    label: "Read vouchers",
  },
  {
    id: "unread_vouchers",
    label: "Unread vouchers",
  },
  {
    id: "gross_sales",
    label: "Gross sales",
  },
  {
    id: "refundable_amount",
    label: "Refundable amount",
  },
  {
    id: "paid_refunds",
    label: "Paid refunds",
  },
  {
    id: "gross_minus_refundable",
    label: "Gross minus refundable",
  },
  {
    id: "corporate_tax_amount",
    label: "Corporate tax amount",
  },
  {
    id: "net_sales",
    label: "Net sales",
  },
  {
    id: "retail_sales_commission",
    label: "Retail sales commission",
  },
  {
    id: "online_sales_commission",
    label: "Online sales commission",
  },
  {
    id: "sum_sales_commission",
    label: "Sum sales commission",
  },
  {
    id: "refundable_commission",
    label: "Refundable commission",
  },
  {
    id: "gross_voucher_payments",
    label: "Gross voucher payments",
  },
  {
    id: "winning_tax",
    label: "Winning tax",
  },
  {
    id: "net_voucher_payments",
    label: "Net voucher payments",
  },
  {
    id: "commission_on_payments",
    label: "Commission on payments",
  },
  {
    id: "interim_payments",
    label: "Interim payments",
  },
  {
    id: "credit_withdrawal",
    label: "Credit withdrawal",
  },
];

const format = (value) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const formatter = new Intl.DateTimeFormat("az-Az", options);
  if (value) {
    return formatter.format(new Date(value));
  }
  return null;
};

export default function ReportsTable({
  data,
  orderBy,
  setOrderBy,
  sortField,
  setSortField,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [splitData, setSplitData] = useState(data?.data.slice(0, 10));

  const classes = useStyles();

  function handleSort(fieldName) {
    const order = fieldName === sortField && !orderBy;
    setSortField(fieldName);
    setOrderBy(order);
  }

  function handleChangePage(e, page) {
    setSplitData(data?.data.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
    setPage(page);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSplitData(data?.data.slice(0, +event.target.value));
  }

  useEffect(() => {
    setSplitData(data?.data.slice(0, 10));
  }, [data]);

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeadList.map(({ id, label }) => (
                <TableCell
                  key={id}
                  className={classes.tableHeadCell}
                  sortDirection={sortField === id ? "asc" : false}
                >
                  <TableSortLabel
                    active={sortField === id}
                    direction={orderBy ? "desc" : "asc"}
                    onClick={() => handleSort(id)}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>Total</TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_gross_sales}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_refundable_amount}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_paid_refunds}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_gross_minus_refundable}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_corporate_tax_amount}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_net_sales}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_retail_sales_commission}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_online_sales_commission}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_both_sale_commission}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_refundable_commission}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_gross_voucher_payments}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_winning_tax}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_net_voucher_payments}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_commission_on_payments}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_interim_payments}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {data?.total_credit_withdrawal}
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
            </TableRow>
            {splitData?.map((ticket, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell className={classes.tableCell}>
                  {ticket?.agent_number}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.agent_name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.taxation_number}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.voucher_sales}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.voucher_cancellations}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.voucher_converted_tickets}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.current_unused_vouchers}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.read_vouchers}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.unread_vouchers}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.gross_sales}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.refundable_amount}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.paid_refunds}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.gross_minus_refundable}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.corporate_tax_amount}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.net_sales}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.retail_sales_commission}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.online_sales_commission}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.sum_sales_commission}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.refundable_commission}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.gross_voucher_payments}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.winning_tax}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.net_voucher_payments}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.commission_on_payments}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.interim_payments}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {ticket?.credit_withdrawal}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data.count > 10 && (
          <ThemeProvider theme={theme}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.count}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        )}
      </div>
    </div>
  );
}
