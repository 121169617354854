import React, {useState, useEffect} from "react";
import {Modal, Button} from "react-bootstrap";
import {Checkbox, FormControlLabel} from "@material-ui/core";

import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {Input} from "app/components";
import "../styles/Roles.css";

const initialValues = {
    name: "",
};

export default function Create({show, onHide, onGroups, skip, take, orderBy, sortField}) {
    const [isError, setIsError] = useState("");

    const CreateSchema = Yup.object().shape({
        name: Yup.string().required("Rol adı daxil edin."),
    });

    const [isDisabled, setIsDisabled] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [data, setData] = useState([]);

    const onPostGroup = (values, resetForm, setSubmitting) => {
        const group = {
            name: values.name,
            permissionIds: permissions
        };
        axios({
            method: "post",
            url: `roles`,
            data: group,
        })
            .then(() => {
                onGroups({skip, take, orderBy, sortField});
                setSubmitting(false);
                resetForm();
                onHide(false);
            })
            .catch((err) => {
                let message =
                    typeof err.response !== "undefined"
                        ? err.response.data.message
                        : err.message;
                setIsError(message);
            });
    };


    const formik = useFormik({
        initialValues,
        validationSchema: CreateSchema,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            onPostGroup(values, resetForm, setSubmitting);
        },
    });

    const resetForm = () => {
        onHide();
        formik.resetForm();
    };

    const getPermissions = () => {
        axios.get(`roles/getPermissions`)
            .then(({data}) => {
                const separateIt = (arr, size) => {
                    var newArr = [];
                    for (var i = 0; i < arr.length; i += size) {
                        var sliceIt = arr.slice(i, i + size
                            )
                        ;
                        newArr.push(sliceIt);
                    }
                    return newArr;
                }
                setData(separateIt(data, 3))
            })
            .catch(() => {
                console.log("error")
            })
    }


    const handleChange = (e, id) => {
        let tempState = [...permissions];
        let updatedState = {}
        if (e.target.checked === true) {
            setPermissions([...tempState, id])
        } else {
            updatedState = tempState.filter(item => item !== id);
            setPermissions(updatedState !== undefined ? updatedState : [])
        }

    };

    const CanBeSubmit = () => {
        if (permissions.length) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }

    useEffect(() => {
        CanBeSubmit();
    }, [permissions]);

    useEffect(() => {
        getPermissions()
    }, [])

    return (
        <Modal
            size="lg"
            aria-labelledby="create"
            centered
            className="modal pr-0"
            show={show}
            onHide={resetForm}
        >
            <form onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="create">Rol yarat</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                                    {isError && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block MuiFormLabel-root Mui-error">
                                                {isError}
                                            </div>
                                        </div>
                                    )}
                                    <Input placeholder="Rolun adı*" className="form-control form-control-solid h-auto py-1 px-6 mr-2" {...formik.getFieldProps("name")}/>
                                </div>
                                {formik.touched.name &&
                                formik.errors.name ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.name}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {data.map((perm, index) => (
                                <div className="col-12 col-md-12 col-lg-12 d-flex flex-wrap mb-2" key={index}>
                                    {perm.map((innerPerm) => (
                                            <div className="col-4 col-md-4 col-lg-4" key={innerPerm.id}>
                                                <FormControlLabel
                                                    control={<Checkbox onChange={(e) => handleChange(e, innerPerm.id)} name="jason" />}
                                                    label={innerPerm.name}
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}


                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" className="w-100 border-0 py-4" style={{backgroundColor: "#00AFF5"}}>+ Rol yarat</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
