import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import { Modal } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getRoles } from "../_redux/usersCrud";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import {Button} from "../../../../app/components";

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
});

const initialValues = {
  id: "",
  name: "",
  surname: "",
  email: "",
  roleId: "",
};

export default function Edit(props) {
  const classes = useStyles();
  const {
    onHide,
    show,
    onGroups,
    chooseGroupId,
    onGroupChoose,
    skip,
    take,
    orderBy,
    userId,
  } = props;

  const user = useSelector((state) => state.auth.user);

  const onPutGroup = (values, resetForm, setSubmitting) => {
    const group = {
      id: values.id,
      name: values.name,
      surname: values.surname,
      email: values.email,
      roleId: values.roleId,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}users/update`, group)
      .then(() => {
        onHide(false);
        resetForm();
        setSubmitting(false);
        onGroups(null, skip, take, orderBy);
      })
      .catch(
        ({
          response: {
            data: { checks },
          },
        }) => {
          setIsError(true);
          setErrorMessage(checks);
        }
      );
  };

  const EditSchema = Yup.object().shape({
    id: Yup.string()
      .min(1, "Ən az 1 rəqəm olmalıdır.")
      .required("ID tələb olunur."),
    name: Yup.string()
      .min(3, "Ən az 3 hərf olmalıdır.")
      .max(255, "Ən çox 255 hərf ola bilər.")
      .required("Ad tələb olunur."),
    surname: Yup.string()
      .min(4, "Ən az 4 hərf olmalıdır.")
      .max(255, "Ən çox 255 hərf ola bilər.")
      .required("Soyad tələb olunur."),
    email: Yup.string()
      .email("Email formatı səhvdir. Örnək: jhon.doe@example.com")
      .required("Email tələb olunur."),
  });

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: EditSchema,
    onSubmit: (values, { resetForm, setSubmitting }, e) => {
      onPutGroup(values, resetForm, setSubmitting);
    },
  });

  const { setFieldValue } = formik;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    onGroupChoose(chooseGroupId)
      .then((res) => {
        console.log(res);
        // setChoosePermissions(choosePermisson);
        setFieldValue("id", res.data.id);
        setFieldValue("name", res.data.name);
        setFieldValue("surname", res.data.surname);
        setFieldValue("userName", res.data.userName);
        setFieldValue("email", res.data.email);
        setFieldValue("phoneNumber", res.data.phoneNumber);
        setFieldValue("roleId", res.data.roleId);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsError(true);
      });
  }, [chooseGroupId, onGroupChoose, setFieldValue]);

  useEffect(() => {
    getRoles()
      .then(({ data }) => {
        const arr = [...data];
        setRoles(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Modal
      size="md"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal pr-0"
    >
      {isLoading ? (
        <div className={classes.progresRoot}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : !isLoading ? (
        //  !isLoading && !isError
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="edit">
              İstifadəçi məlumatlarını yenilə
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 pl-4 pr-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    {isError && errorMessage ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                          {errorMessage.id}
                        </div>
                      </div>
                    ) : null}
                    <TextField
                      id="id"
                      name="id"
                      label="ID"
                      placeholder="ID"
                      className="w-100 d-none"
                      error={getInputClasses("id")}
                      {...formik.getFieldProps("id")}
                    />
                  </div>
                  {formik.touched.id && formik.errors.id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.id}</div>
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    {isError && errorMessage ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                          {errorMessage.name}
                        </div>
                      </div>
                    ) : null}
                    <TextField
                      id="name"
                      name="name"
                      label="Ad"
                      placeholder="Ad"
                      className="w-100"
                      error={getInputClasses("name")}
                      {...formik.getFieldProps("name")}
                    />
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.name}</div>
                    </div>
                  ) : null}
                </div>

                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    {isError && errorMessage ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                          {errorMessage.surname}
                        </div>
                      </div>
                    ) : null}

                    <TextField
                      id="surname"
                      name="surname"
                      label="Soyad"
                      placeholder="Soyad"
                      className="w-100"
                      error={getInputClasses("surname")}
                      {...formik.getFieldProps("surname")}
                    />
                  </div>
                  {formik.touched.surname && formik.errors.surname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.surname}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    {isError && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                          {isError}
                        </div>
                      </div>
                    )}
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="Email"
                      className="w-100"
                      error={getInputClasses("email")}
                      {...formik.getFieldProps("email")}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>

                <div
                  className={`col-12 col-md-12 col-lg-12 ${
                    userId === user.id ? "d-none" : ""
                  }`}
                >
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    {isError && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block MuiFormLabel-root Mui-error">
                          {isError}
                        </div>
                      </div>
                    )}
                    <FormControl
                      error={getInputClasses("roleId")}
                      className="w-100"
                      placeholder="roleId"
                    >
                      <InputLabel id="status">Role</InputLabel>
                      <Select
                        {...formik.getFieldProps("roleId")}
                        labelId="roleId"
                        id="roleId"
                      >
                        {roles.map((role, index) => (
                          <MenuItem key={index} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.roleId && formik.errors.roleId ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.roleId}
                          </div>
                        </div>
                      ) : null}
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="w-100 border-0 py-4" style={{backgroundColor: "#00AFF5"}}>İstifadəçini yenilə</Button>
          </Modal.Footer>
        </form>
      ) : null

      // <div className="p-5">
      //   <Alert variant="info">Xəta baş verdi.</Alert>
      // </div>
      }
    </Modal>
  );
}
