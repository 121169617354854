import React from "react";
// eslint-disable-next-line no-restricted-imports
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import {makeStyles} from "@material-ui/core";
// eslint-disable-next-line no-restricted-imports
import {azAZ} from '@material-ui/core/locale';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    CircularProgress, TableSortLabel,
    createMuiTheme,
    ThemeProvider,
} from "@material-ui/core";
import {TablePagination, Tooltip} from "@material-ui/core";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import {format} from "date-fns";

const theme = createMuiTheme(azAZ);

const headRows = [
    {
        id: "createdDate",
        numeric: false,
        disablePadding: true,
        label: "Yaradılma tarixi",
    },
    {
        id: "customerPhoneNumber",
        numeric: false,
        disablePadding: true,
        label: "Mobil nömrə",
    },
    {
        id: "totalBetAmount",
        numeric: false,
        disablePadding: true,
        label: "Mərc məbləği",
    },
    {
        id: "totalWinAmount",
        numeric: false,
        disablePadding: true,
        label: "Uduş məbləği",
    },
    {
        id: "matchId",
        numeric: false,
        disablePadding: true,
        label: "Match ID",
    },
    {
        id: "moveId",
        numeric: false,
        disablePadding: true,
        label: "Move ID",
    },
    {
        id: "roundId",
        numeric: false,
        disablePadding: true,
        label: "Round ID",
    },
    {
        id: "balanceAfterBet",
        numeric: false,
        disablePadding: true,
        label: "Sonrakı balans",
    },
    {
        id: "balanceAfterWin",
        numeric: false,
        disablePadding: true,
        label: "Uduşdan sonrakı balans",
    },
    {
        id: "voucherCode",
        numeric: false,
        disablePadding: true,
        label: "Voucher code",
    },
    {
        id: "agentNumber",
        numeric: false,
        disablePadding: true,
        label: "Agent number",
    },
    {
        id: "operations",
        numeric: false,
        disablePadding: true,
        label: "Əməliyyatlar",
    },
];

function EnhancedTableHead(props) {
    const {
        orderBy,
        sortField,
        onRequestSort,
    } = props;

    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {headRows.map((row, index) => {
                    if (row.id === "operations") {
                        return (
                            <TableCell key={index}
                                       align={row.numeric ? "right" : "left"} className={classes.tableHeadCell}>
                                <div>
                                    {row.label}
                                </div>
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell sortDirection={sortField === row.id ? "asc" : false} key={index}
                                       align={row.numeric ? "right" : "left"} className={classes.tableHeadCell}>
                                <TableSortLabel active={sortField === row.id} direction={orderBy ? "desc" : "asc"}
                                                onClick={() => {
                                                    onRequestSort(row.id)
                                                }}>
                                    {row.label}
                                </TableSortLabel>
                            </TableCell>
                        )
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        marginTop: "10px",
        borderRadius: "18px",
        background: "#F5F5F5",
        padding: "0 8px 8px 8px"
    },
    tableRow: {
        borderRadius: "18px",
        border: "unset",
        padding: "16px",
        "&:hover": {
            backgroundColor: "unset !important"
        }
    },
    table: {
        minWidth: 750,
        borderCollapse: "separate",
        borderSpacing: "0 4px"
    },
    tableHeadCell: {
        border: "unset",
        padding: "4px",
        '&:first-child': {
            paddingLeft: "10px"
        },
        '&:last-child': {
            paddingRight: "10px"
        }

    },
    tableCell: {
        border: "unset",
        background: "#FFFFFF",
        marginBottom: "16px",
        padding: "4px",
        '&:first-child': {
            borderRadius: "18px 0 0 18px",
            paddingLeft: "10px"
        },
        '&:last-child': {
            borderRadius: "0 18px 18px 0",
            paddingRight: "10px"
        }
    },
    tableWrapper: {
        overflowX: "auto",
    },
    progresRoot: {
        width: "100%",
        padding: "32px 16px",
    },
    progress: {
        margin: "auto",
        display: "block",
        color: "#e2241b",
    },
}));

export default function EnhancedTable({
                                          groups,
                                          isLoading,
                                          isError,
                                          deleteGroupsIds,
                                          setDeleteGroupsIds,
                                          take,
                                          orderBy,
                                          setSortField,
                                          setOrderBy,
                                          setSkip,
                                          setTake,
                                          sortField,
                                          totalCount,
                                          setCustomerBets,
                                          setShowCustomerBets
                                      }) {
    const classes = useStyles();


    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = groups.map((n) => n.id);
            setDeleteGroupsIds(newSelecteds);
            return;
        }
        setDeleteGroupsIds([]);
    }

    const isSelected = (name) => deleteGroupsIds.indexOf(name) !== -1;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (field) => {

        const isOrder = (field === sortField && !orderBy)

        setSortField(field);
        setOrderBy(isOrder)

    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
        setSkip(take * newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setTake(+event.target.value);
        setPage(0);
        setSkip(0);
    }

    return (
        <div className={classes.root}>
            {isLoading && (
                <div className={classes.progresRoot}>
                    <CircularProgress className={classes.progress}/>
                </div>
            )}
            <div className={classes.tableWrapper}>
                {groups.length ? (
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={groups.length}
                            onRequestSort={handleRequestSort}
                            sortField={sortField}
                            orderBy={orderBy}
                        />
                        <TableBody>
                            {groups.map((row) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.transactionId}
                                    className={classes.tableRow}
                                >
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.createdDate ? format(new Date(row.createdDate), "dd.MM.yyyy HH:mm:ss") : ""}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.customerPhoneNumber}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.totalBetAmount}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.totalWinAmount}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.matchId}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.moveId}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.roundId}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.balanceAfterBet}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.balanceAfterWin}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.voucherCode}
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                               className={classes.tableCell}>
                                        {row.agentNumber}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableCell}
                                    >
                                        <Tooltip title={row.isActive ? "Deaktiv et" : "Aktivləşdir"}
                                                 placement="top">
                                            <button
                                                aria-label="edit"
                                                className={`btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info`}
                                                onClick={() => {
                                                    setCustomerBets(row.bets ?? []);
                                                    setShowCustomerBets(true);
                                                }}
                                            >
                                                <EyeIcon/>
                                            </button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : !groups.length && !isLoading && !isError ? (
                    <AlertComponent variant="info" message="Məlumat tapılmadı."/>
                ) : (!isLoading && isError && (!isLoading && isError && (<AlertComponent
                    variant="danger"
                    message="Şəbəkəylə bağlantı kəsildi."
                />)))}
            </div>
            {totalCount > 10 && (
                <ThemeProvider theme={theme}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next Page",
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </ThemeProvider>
            )}
        </div>
    );
}
