import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import axios from "axios";
import {makeStyles} from "@material-ui/core";
import "../styles/Roles.css";

const useStyles = makeStyles({
    card: {
        borderRadius: "20px",
        background: "#00AFF5",
        padding: "4px 8px",
        color: "#FFFFFF",
        fontSize: "12px"
    },
    container: {
        display: "flex",
        gap: "16px",
        flexWrap: "wrap"
    }
})

export default function View({show, onHide, roleId}) {
    const [permissions, setPermissions] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        axios
            .get('/roles/getPermissions')
            .then(({data: allPermissions}) => {
                axios
                    .get(`roles/getByIdForUpdate?id=${roleId}`)
                    .then(({data: rolePermissions}) => {
                        setPermissions(allPermissions.filter(permission => rolePermissions.permissionIds.includes(permission.id)))
                    })
                    .catch(() => null)
            })
            .catch(() => null);

    }, [roleId])

    return (
        <Modal
            size="lg"
            aria-labelledby="create"
            centered
            className="modal pr-0"
            show={show}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="create">Permissionlar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.container}>
                    {permissions.map(permission => (
                        <div key={permission.id} className={classes.card}>{permission.name}</div>
                    ))}
                </div>
            </Modal.Body>

        </Modal>
    );
}
