import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const USER_URL = `${REACT_APP_API_URL}users`;

export const GET_USERS_URL = `${REACT_APP_API_URL}users/filter`;
export const CREATE_USER_URL = `${REACT_APP_API_URL}users/register`;
export const STATUS_UPDATE_USER_URL = `users/updateStatus`;
export const GET_ROLES_URL = `${REACT_APP_API_URL}roles`;

export const UPDATE_PASSWORD_URL = `users/updatePassword`;
export const CHANGE_PASSWORD_URL = `users/changePassword`;

export const GET_USER_ITEM_URL = `${REACT_APP_API_URL}users/byId?id=`;

export const GET_USER_PERMISSONS = `users/permissions`;

export function getUsers(filter = {}) {
  const {
    skip,
    take,
    sortField,
    orderBy,
    name,
    surname,
    email,
    username,
    phonenumber,
    lastlogindate,
    roleId,
  } = filter;
  const URL = `${GET_USERS_URL}?${skip ? `Skip=${skip}` : "Skip=0"}${
    take ? `&Take=${take}` : ""
  }${orderBy ? `&OrderBy=${orderBy}` : ""}${
    sortField ? `&SortField=${sortField}` : ""
  }${name ? `&Name=${name}` : ""}${surname ? `&Surname=${surname}` : ""}${
    email ? `&Email=${email}` : ""
  }${username ? `&UserName=${username}` : ""}${
    phonenumber ? `&PhoneNumber=${phonenumber}` : ""
  }${lastlogindate ? `&LastLoginDate=${lastlogindate}` : ""}${
    roleId ? `&RoleId=${roleId}` : ""
  }`;
  return axios.get(URL);
}

export function createUser(user, header) {
  return axios.post(CREATE_USER_URL, user, header);
}

export function putUserPassword(password, header) {
  return axios.put(UPDATE_PASSWORD_URL, password, header);
}

export function changePassword(password, header) {
  return axios.put(CHANGE_PASSWORD_URL, password, header);
}

export function getUserItem(userId) {
  const URL = `${GET_USER_ITEM_URL}${userId}`;
  return axios.get(URL);
}

export function putUserItem(user, header) {
  return axios.put(USER_URL, user, header);
}

export function deleteUserItem(userId) {
  return axios.delete(USER_URL, userId);
}

export function getUserPermissons() {
  return axios.get(GET_USER_PERMISSONS);
}

export function getUpdateStatusItem(userId, header) {
  const URL = `${STATUS_UPDATE_USER_URL}`;
  return axios.put(URL, userId, header);
}

export function getRoles() {
  return axios.get(GET_ROLES_URL);
}
