import React from "react";
import {Modal, Button} from "react-bootstrap";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        overflow: "auto"
    }
})

const headRows = [
    {
        id: "market",
        numeric: false,
        disablePadding: true,
        label: "Market",
    },
    {
        id: "outCome",
        numeric: false,
        disablePadding: true,
        label: "Outcome",
    },
    {
        id: "specifier",
        numeric: false,
        disablePadding: true,
        label: "Specifier",
    },
    {
        id: "resume",
        numeric: false,
        disablePadding: true,
        label: "Resume",
    },
    {
        id: "betAmount",
        numeric: false,
        disablePadding: true,
        label: "Bet amount",
    },
    {
        id: "rate",
        numeric: false,
        disablePadding: true,
        label: "Rate",
    },
    {
        id: "winAmount",
        numeric: false,
        disablePadding: true,
        label: "Win amount",
    }
];


export default function CustomerBets({
                                            show,
                                            onHide,
                                            customerBets
                                        }) {
    const classes = useStyles();

    return (
        <Modal
            size="xl"
            centered
            className="modal pr-0"
            show={show}
            onHide={onHide}
        >
                <Modal.Header closeButton>
                    <Modal.Title id="create">Customer bets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <div className={classes.container}>
                       <Table>
                           <TableHead>
                               <TableRow>
                                   {headRows.map((row, index) => (
                                       <TableCell key={index}>
                                           {row.label}
                                       </TableCell>
                                   ))}
                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {customerBets.map((row, index) => (
                                   <TableRow key={index}>
                                       <TableCell>{row.market}</TableCell>
                                       <TableCell>{row.outcome}</TableCell>
                                       <TableCell>{row.specifier}</TableCell>
                                       <TableCell>{row.resume}</TableCell>
                                       <TableCell>{row.betAmount}</TableCell>
                                       <TableCell>{row.rate}</TableCell>
                                       <TableCell>{row.winAmount}</TableCell>
                                   </TableRow>
                               ))}
                           </TableBody>
                       </Table>
                   </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => {
                            onHide(false);
                        }}
                    >
                        Bağla
                    </Button>
                </Modal.Footer>
        </Modal>
    );
}
