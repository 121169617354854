import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {CircularProgress, Snackbar} from "@material-ui/core";
import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export default function TaxAndCommissions() {
    const [id, setId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");

    const initialValues = {
        corporateTax: 0,
        salesCommission: 0,
        refundableCommission: 0,
        winningTax: 0,
        paymentCommission: 0,
        overdueInterest: 0
    }

    const validationSchema = Yup.object({
        corporateTax: Yup.number("Yalnız rəqəm daxil edin."),
        salesCommission: Yup.number("Yalnız rəqəm daxil edin."),
        refundableCommission: Yup.number("Yalnız rəqəm daxil edin."),
        winningTax: Yup.number("Yalnız rəqəm daxil edin."),
        paymentCommission: Yup.number("Yalnız rəqəm daxil edin."),
        overdueInterest: Yup.number("Yalnız rəqəm daxil edin.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            setIsSubmitting(true)
            const body = {
                id, ...values
            }
            updateTaxAndCommissions(body)
                .then((res) => {
                    setIsSubmitting(false);
                    setMessage("Dəyişiklik uğurla yeniləndi.");
                    setShow(true);
                })
                .catch((err) => {
                    setIsSubmitting(false);
                    setMessage("Xəta baş verdi.");
                })
        }
    })

    function getTaxAndCommissions() {
        axios.get(`taxAndCommissions`)
            .then(({data}) => {
                setId(id);
                formik.resetForm({
                    values: data
                })
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    function updateTaxAndCommissions(body) {
        return axios.put(`taxAndCommissions`, body)
    }

    useEffect(() => {
        getTaxAndCommissions();
    }, [])

    function handleClose() {
        setShow(false);
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShow(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div className="row bg-white rounded p-4">
            {isLoading
                ? <div className="d-flex justify-content-center w-100">
                    <CircularProgress style={{color: "#FF0009"}}/>
                </div>
                : <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="corporateTax">Korperativ vergisi</label>
                                <input type="text" className="form-control" id="corporateTax"
                                       placeholder="Corporate Tax" {...formik.getFieldProps("corporateTax")}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="salesCommission">Satış kommisiyası</label>
                                <input type="text" className="form-control" id="salesCommission"
                                       placeholder="Sales Commission" {...formik.getFieldProps("salesCommission")}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="refundableCommission">Geri qaytarıla bilən kommisiya</label>
                                <input type="text" className="form-control" id="refundableCommission"
                                       placeholder="Refundable Commission" {...formik.getFieldProps("refundableCommission")}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="winningTax">Uduş vergisi</label>
                                <input type="text" className="form-control" id="winningTax"
                                       placeholder="Winning Tax" {...formik.getFieldProps("winningTax")}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="paymentCommission">Ödəniş kommisiyası</label>
                                <input type="text" className="form-control" id="paymentCommission"
                                       placeholder="Payment Commission" {...formik.getFieldProps("paymentCommission")}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="overdueInterest">Vaxtı keçmiş faiz</label>
                                <input type="text" className="form-control" id="overdueInterest"
                                       placeholder="Overdue Interest" {...formik.getFieldProps("overdueInterest")}/>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button disabled={isSubmitting} type="submit">Göndər</Button>
                            </div>
                        </div>

                    </div>
                </form>
            }
            <Snackbar
                open={show}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}
                action={action}
            />
        </div>
    )
}