import React, {useEffect, useState} from "react";
import DataTables from "./components/DashboardTables";
import Edit from "./modals/Edit";
import EditPassword from "./modals/EditPassword";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import {Pagination} from "@material-ui/lab";
import axios from "axios";
import {updateObject} from "../../../helpers/updateObject";
import {getRoles, getUsers} from "./_redux/usersCrud";
import {Button, Input, Select} from "../../../app/components";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    createUserButton: {
        backgroundColor: "#00b0f6",
        padding: "8px 12px",
        fontSize: "11px",
        whiteSpace: "nowrap",
        fontWeight: 500,
        borderRadius: "8px",
        color: "#fff !important",
    }
})


const initialFilter = {
    name: "",
    email: "",
}

function Dashboard() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [sortField, setSortField] = useState("record_date_time");
    const [orderBy, setOrderBy] = useState(false);
    const [groups, setGroups] = useState([]);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
    const [userId, setUserId] = useState(undefined);

    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showEditPass, setShowEditPass] = useState(false);
    const [filterInputs, setFilterInputs] = useState(initialFilter);
    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState(null);

    const classes = useStyles();

    const pageCount = Math.ceil(totalCount / take);

    const onGroups = (filterInputs, skip, take, orderBy) => {
        setIsLoading(true);

        const filterData = {...filterInputs, skip, take, orderBy, sortField, roleId};
        getUsers(filterData)
            .then(({data: {data, totalCount}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const onGroupChoose = (id) => {
        return axios.get(`users/byId?id=${id}`);
    };
    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value,
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups(filterInputs, skip, take, orderBy, sortField);
        }, 500);

        return () => {
            clearTimeout(getAllTimer);
        };
    }, [filterInputs, skip, take, orderBy, sortField, roleId]);

    useEffect(() => {
        getRoles().then(res => {
            const roles = res.data?.map(role => ({
                label: role.name,
                value: role.id
            })) ?? [];
            roles.unshift({
                label: "Hamısı",
                value: ""
            })
            setRoles(roles);
        }).catch(() => null);
    }, [])

    return (
        <div className="row bg-white">
            <div className="col-12 d-flex align-items-center pb-4">
                <h2 className="mb-0 font-weight-bolder">İstifadəçilər</h2>
                <Button
                    onClick={() => setShowCreate(true)}
                    className={`ml-auto mb-0 border-0 text-dark ${classes.createUserButton}`}
                >
                    + Yeni istifadəçi yarat
                </Button>
            </div>
            <div className="d-flex w-100">
                <Input
                    name="name"
                    value={filterInputs.name}
                    onChange={filterInputsUpdate}
                    placeholder="Ad"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="email"
                    value={filterInputs.email}
                    onChange={filterInputsUpdate}
                    placeholder="Email"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Select placeholder="Rol" options={roles} onChange={(e) => setRoleId(e.value)}/>
            </div>
            <DataTables
                groups={groups}
                onGroups={onGroups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowEdit={setShowEdit}
                setShowEditPass={setShowEditPass}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                sortField={sortField}
                setSortField={setSortField}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                totalCount={totalCount}
                skip={skip}
                take={take}
                filterInputs={filterInputs}
                setUserId={setUserId}
                setTake={setTake}
                setSkip={setSkip}
            />
            <div className="d-flex justify-content-center mt-2 w-100">
                <Pagination count={pageCount} onChange={(_, page) => setSkip(take * (page - 1))}/>
            </div>
            {showCreate && (
                <Create
                    show={showCreate}
                    onHide={setShowCreate}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                />
            )}
            {showRemove && (
                <Delete
                    id={chooseGroupId}
                    show={showRemove}
                    onHide={setShowRemove}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    filterInputs={filterInputs}
                    sortField={sortField}
                />
            )}
            {showEdit && (
                <Edit
                    show={showEdit}
                    onHide={setShowEdit}
                    onGroups={onGroups}
                    chooseGroupId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    userId={userId}
                />
            )}
            {showEditPass && (
                <EditPassword
                    show={showEditPass}
                    onHide={setShowEditPass}
                    onGroups={onGroups}
                    chooseGroupId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                />
            )}
        </div>
    );
}

export default Dashboard;
