import React, {useEffect, useState} from "react";
import DataTables from "./components/AgentsTable";
import Edit from "./modals/Edit";
import EditBalance from "./modals/EditBalance";
import ChangePassword from "./modals/ChangePassword";
import Delete from "./modals/Remove";
import Create from "./modals/Create";
import Filter from "./modals/Filter";
import UpdateSales from "./modals/UpdateSales";
import UpdatePayment from "./modals/UpdatePayment";
import AgentTraces from "./modals/AgentTraces";
import axios from "axios";
import {updateObject} from "../../../helpers/updateObject";
import {Button} from "react-bootstrap";
import {objectToQueryString} from "../../../_metronic/_helpers";
import RenderIf from "../../components/RenderIf";
import {makeStyles} from "@material-ui/core/styles";
import {PaymentIcon, SalesIcon} from "../../components/Icons";
import {Input, Select} from "../../components";
import {shallowEqual, useSelector} from "react-redux";

const useStyles = makeStyles({
    redButton: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #E2241B",
        marginInline: "4px",
        padding: "6px 12px",
        color: "#E2241B",
        fontWeight: 500,
        fontSize: "14px",
        '& svg': {
            fill: "#E2241B",
            marginRight: "6px"
        }
    },
    greenButton: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        border: "1px solid #40C4BF",
        marginInline: "4px",
        padding: "6px 12px",
        color: "#40C4BF",
        fontWeight: 500,
        fontSize: "14px",
        '& svg': {
            fill: "#40C4BF",
            marginRight: "6px"
        }
    },
    createAgentButton: {
        backgroundColor: "#00b0f6",
        padding: "8px 12px",
        fontSize: "11px",
        whiteSpace: "nowrap",
        fontWeight: 500,
        borderRadius: "8px",
        color: "#fff !important",
    }
})

const statusOptions = [
    {
        label: "Hamısı",
        value: ""
    }, {
        label: "Aktiv",
        value: true
    }, {
        label: "Deaktiv",
        value: false
    }]

function Agents() {
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [orderBy, setOrderBy] = useState(false);
    const [sortField, setSortField] = useState("");
    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [chooseGroupId, setChooseGroupId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [deleteGroupsIds, setDeleteGroupsIds] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showUpdateSales, setShowUpdateSales] = useState(false);
    const [showUpdatePayment, setShowUpdatePayment] = useState(false);
    const [showEditBalance, setShowEditBalance] = useState(false);
    const [showAgentTraces, setShowAgentTraces] = useState(false);
    const [cities, setCities] = useState([]);
    const [regions, setRegions] = useState([])
    const [filterInputs, setFilterInputs] = useState({
        name: "",
        number: "",
        deposit: "",
        debt: "",
        isActive: "all",
    });

    const {
        Agent_Create,
        Agent_Update,
        Agent_Delete,
        Agent_ChangeActiveStatus,
        Stop_Payments,
        Stop_Sales
    } = useSelector(state => state.auth.user.permissions, shallowEqual)

    const isFrozenBySystem = groups[0]?.isFrozenBySystem;
    const isPaymentBlocked = groups[0]?.isPaymentBlocked;

    const handleReset = () => {
        setFilterInputs({
            name: "",
            number: "",
            deposit: "",
            debt: "",
            isActive: "",
        })
    }

    function handleChange() {
        setShowFilter((key) => !key);
    }

    const onGroups = (filter = {}) => {
        setIsLoading(true);

        const {isActive, ...rest} = filter;

        const query = objectToQueryString({...rest, isActive: isActive === 'all' ? "" : isActive});

        axios
            .get(`agents/filter?${query}`)
            .then(({data: {data, totalCount}}) => {
                setIsLoading(false);
                setGroups(data);
                setTotalCount(totalCount);
                setIsError(false);
            })
            .catch(() => {
                setIsLoading(false);
                setGroups([]);
                setIsError(true);
            });
    };

    const onGroupChoose = (id) => {
        return axios.get(`agents/byId?id=${id}`);
    };

    const getCities = () => {
        setIsLoading(true)
        axios.get(`cities/filter`)
            .then(({data: {data}}) => {
                setCities(data)
                setIsLoading(false)
            })
            .catch(() => {
                console.log("error")
            })
    };

    const getRegions = (cityId) => {
        if (cityId !== "") {
            axios.get(`cities/regions?CityId=${cityId}`)
                .then(({data: {data}}) => {

                    setRegions(data)
                })
                .catch(() => {
                    console.log("error")
                })
        } else {
            setRegions([])
        }
    }

    const downloadExcel = async () => {
        const {isActive, ...rest} = filterInputs;
        const query = objectToQueryString({...rest, isActive: isActive === 'all' ? "" : isActive});

        try {
            const {data} = await axios({
                url: `agents/filter?${query}`,
                method: "GET",
                responseType: "blob",
                headers: {
                    "X-Content-Type": "application/xlsx"
                    }
            });
            const blob = new Blob([data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "Agents.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
        }
    }

    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            [e.target.name]: e.target.value
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
    };

    useEffect(() => {
        const getAllTimer = setTimeout(() => {
            onGroups({...filterInputs, skip, take, orderBy, sortField});
        }, 500)

        return () => {
            clearTimeout(getAllTimer)
        }
    }, [filterInputs, skip, take, orderBy, sortField]);

    const classes = useStyles();

    return (
        <div className="row bg-white rounded">
            <div className="col-12 d-flex align-items-center pb-4">
                <h2 className="mb-0 font-weight-bolder">Agentlər</h2>
                <div className="d-flex pl-15">
                    <span className="mr-4 text-nowrap">Total: {totalCount}</span>
                </div>
                <RenderIf
                    condition={typeof isFrozenBySystem !== 'undefined' || typeof isPaymentBlocked !== 'undefined'}>
                    <div className="w-100 d-flex justify-content-end">
                        <Button onClick={downloadExcel} variant="outline-success" className="mr-2">Download excel</Button>
                        <RenderIf condition={typeof isFrozenBySystem !== 'undefined' && Stop_Sales}>
                            <button
                                style={{padding: "6px 12px", fontSize: "11px", whiteSpace: "nowrap"}}
                                onClick={() => setShowUpdateSales(true)}
                                className={isFrozenBySystem ? classes.greenButton : classes.redButton}
                            >
                                <SalesIcon/>{isFrozenBySystem ? "Satışı aktivləşdir" : "Satışı dayandır"}
                            </button>
                        </RenderIf>
                        <RenderIf condition={typeof isPaymentBlocked !== 'undefined'  && Stop_Payments}>
                            <button
                                style={{padding: "6px 12px", fontSize: "11px", whiteSpace: "nowrap"}}
                                onClick={() => setShowUpdatePayment(true)}
                                className={isPaymentBlocked ? classes.greenButton : classes.redButton}
                            >
                                <PaymentIcon/>
                                {isPaymentBlocked ? "Uduş ödənişini aktivləşdir" : "Uduş ödənişini dayandır"}
                            </button>
                        </RenderIf>
                    </div>
                </RenderIf>
                <RenderIf condition={Agent_Create}>
                    <Button
                        onClick={() => setShowCreate(true)}
                        className={`ml-auto mb-0 border-0 text-dark ${classes.createAgentButton}`}
                    >
                        + Yeni agent yarat
                    </Button>
                </RenderIf>
            </div>
            <div className="d-flex w-100">
                <Input
                    name="number"
                    value={filterInputs.number}
                    onChange={filterInputsUpdate}
                    placeholder="Agent number"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="name"
                    value={filterInputs.name}
                    onChange={filterInputsUpdate}
                    placeholder="Ad"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Input
                    name="deposit"
                    value={filterInputs.deposit}
                    onChange={filterInputsUpdate}
                    placeholder="Deposit"
                    className="form-control form-control-solid py-1 px-6 mr-2"
                    style={{width: "200px"}}
                />
                <Select placeholder="Status" options={statusOptions} onChange={(e) => setFilterInputs(prevState => ({
                    ...prevState, isActive: e.value
                }))}/>
            </div>
            <DataTables
                groups={groups}
                isLoading={isLoading}
                isError={isError}
                setChooseGroupId={setChooseGroupId}
                setShowCreate={setShowCreate}
                setShowRemove={setShowRemove}
                setShowEdit={setShowEdit}
                setShowEditBalance={setShowEditBalance}
                setDeleteGroupsIds={setDeleteGroupsIds}
                deleteGroupsIds={deleteGroupsIds}
                onGroups={onGroups}
                skip={skip}
                setSkip={setSkip}
                setTake={setTake}
                setOrderBy={setOrderBy}
                setSortField={setSortField}
                take={take}
                orderBy={orderBy}
                sortField={sortField}
                totalCount={totalCount}
                setShowChangePassword={setShowChangePassword}
                filterInputs={filterInputs}
                setShowAgentTraces={setShowAgentTraces}
            />
            {showCreate && (
                <Create
                    show={showCreate}
                    onHide={setShowCreate}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                    filterInputsUpdate={filterInputsUpdate}
                    cities={cities}
                    regions={regions}
                    setRegions={setRegions}
                    setCities={setCities}
                    getCities={getCities}
                    getRegions={getRegions}
                />
            )}

            {showEdit && (
                <Edit
                    show={showEdit}
                    onHide={setShowEdit}
                    onGroups={onGroups}
                    chooseGroupId={chooseGroupId}
                    onGroupChoose={onGroupChoose}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
             {showEditBalance && (
                <EditBalance
                    show={showEditBalance}
                    onHide={setShowEditBalance}
                    agentId={chooseGroupId}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            {showChangePassword && (
                <ChangePassword
                    id={chooseGroupId}
                    show={showChangePassword}
                    onHide={setShowChangePassword}
                />
            )}
            {showRemove && (
                <Delete
                    id={chooseGroupId}
                    show={showRemove}
                    onHide={setShowRemove}
                    onGroups={onGroups}
                    skip={skip}
                    take={take}
                    orderBy={orderBy}
                    sortField={sortField}
                    filterInputs={filterInputs}
                />
            )}
            <Filter
                show={showFilter}
                onHide={handleChange}
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs}
                resetFilter={handleReset}
            />
            {
                showUpdateSales && (
                    <UpdateSales
                        filterInputs={filterInputs}
                        skip={skip}
                        take={take}
                        show={showUpdateSales}
                        onHide={setShowUpdateSales}
                        onGroups={onGroups}
                        orderBy={orderBy}
                        sortField={sortField}
                        isFrozenBySystem={isFrozenBySystem}
                    />
                )
            }
            {
                showUpdatePayment && (
                    <UpdatePayment
                        filterInputs={filterInputs}
                        skip={skip}
                        take={take}
                        show={showUpdatePayment}
                        onHide={setShowUpdatePayment}
                        onGroups={onGroups}
                        orderBy={orderBy}
                        sortField={sortField}
                        isPaymentBlocked={isPaymentBlocked}
                    />
                )
            }
             {
                showAgentTraces && (
                    <AgentTraces
                        show={showAgentTraces}
                        onHide={setShowAgentTraces}
                        agentId={chooseGroupId}
                    />
                )
            }
        </div>
    );
}

export default Agents;
