import React from "react";
import {Modal, Button} from "react-bootstrap";
import { useDispatch } from "react-redux";
import {actions} from "app/components/Snackbar/snackbarReducer";

import axios from "axios";

export default function Reprint({onHide, show, id, onGroups, skip, take, orderBy, sortField, filterInputs}) {
    const dispatch = useDispatch();

    const onReprint = (e) => {
        e.preventDefault();

        axios({
            method: "put",
            url: `vouchers/allowReprint`,
            data: {voucherId: id}
        })
            .then((res) => {
                onGroups({...filterInputs, skip, take, orderBy, sortField});
                onHide();
                dispatch(actions.setSnackbar({variant: "success", message: "İcazə verildi."}))
            })
            .catch((err) => {
                dispatch(actions.setSnackbar({variant: "error", message: err?.response?.data?.message ?? "An error occurred."}))
            });
    };

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
            <form onSubmit={onReprint}>
                <Modal.Header closeButton>
                    <Modal.Title id="delete">Reprint</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12 pl-4 pr-4">
                        <div className="row">İcazə verməyə əminsiniz?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => onHide(false)}>
                        Ləğv
                    </Button>
                    <Button type="submit">Təsdiqlə</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
